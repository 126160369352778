import Tooltip from "react-tooltip-lite";
import PropTypes from "prop-types";
import React from "react";
import "./Tooltip.scss";

const StyledTooltip = (props) => {
    const {
        content,
        children,
        direction,
        color,
        background = "#ebf4fb",
        arrowSize,
        distance,
        useHover = true,
        arrow = true,
        mouseOutDelay = 400,
        zIndex = 1000,
        ...restProps
    } = props;
    return (
        <Tooltip
            content={content}
            children={children}
            direction={direction}
            color={color}
            background={background}
            arrowSize={arrowSize}
            distance={distance}
            arrow={arrow}
            useHover={content? useHover:false}
            mouseOutDelay={mouseOutDelay}
            zIndex={zIndex}
            {...restProps}
        />
    );
};
export default StyledTooltip;

StyledTooltip.propTypes = {
    content: PropTypes.node.isRequired,
    children: PropTypes.node, //content wrapped inside Tooltip
    direction: PropTypes.string, //tooltip direction top,bottom..
    color: PropTypes.string,
    background: PropTypes.string,
    arrowSize: PropTypes.number,
    distance: PropTypes.number, //distance form content to Tooltip
    arrow: PropTypes.bool,
    useHover: PropTypes.bool,
};
