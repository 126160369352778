import React, { Component } from "react";
import { apiCall } from "../utilities/apiCall";
import { API_EXCEPTION_SAVE, Page_ErrorHandler } from "../utilities/apiUrls";
import { getSessionValue, openPage } from "../utilities/commonHelpers";

export default class ErrorBoundary extends Component {
  constructor(props) {
    super(props);

    // default error states
    this.state = {
      hasError: false,
      info: null,
      error: { message: "" },
    };
  }
  logError = (message, stacktrace, source) => {
    apiCall(API_EXCEPTION_SAVE, "POST", {
      message: message,
      sourceapp: source,
      stacktrace: stacktrace,
      UserID: getSessionValue("UserID"),
    }).then((response) => {
      if (response.content.Data)
        console.log("Error occured and logged in the DB");

      if (process.env.REACT_APP_PRODUCT !== "CP") {
         openPage(`${Page_ErrorHandler}?ErrorID=${response.content.Data}`, "Error Handler", false, false, true);
      } else {
        openPage(`/ErrorPage?isSessionTimeOut=false&errorInfo=${this.state.error.message}`, "Error Handler", false, false, true);
      }
    });
  };

  onReload = () => {
    if (this.props.onReLoadClick) {
      this.props.onReLoadClick();
    } else {
      window.location.reload();
    }
  };

  componentDidCatch = (error, info) => {
    // Add error to state
    this.setState({
      hasError: true,
      error: error,
      info: info,
    });
    this.logError(error.message, info.componentStack, "Error from react");
  };

  render() {
    if (this.state.hasError) {
      //error from customer portal
      if (process.env.REACT_APP_PRODUCT === "CP") {
        return null;
      }
      // console.log(this.state.info.apiCall);
      // Some error was thrown. Let's display something helpful to the user
      return (
        <div>
          <h2>
            Some thing went wrong,Please{" "}
            <button className="btn-link" onClick={this.onReload}>
              re-load!
            </button>
          </h2>
        </div>
      );
    } else {
      // No errors were thrown. As you were.
      return this.props.children;
    }
  }
}
