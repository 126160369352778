


export const getSessionDetails = function (key) {
    const clientDetails = window.localStorage.getItem("MMClientVars");
    if (clientDetails && isActiveSession()) {
        if (key === "All") {
            return JSON.parse(clientDetails);
        } else {
            return JSON.parse(clientDetails)[key];
        }
    } else {
        return "";
    }
};

export const setClientSession = function (clientDetails) {
    localStorage.setItem("MMClientVars", JSON.stringify(clientDetails));
};

export const isActiveSession = function () {
    const clientDetails = window.localStorage.getItem("MMClientVars");
    if (clientDetails && JSON.parse(clientDetails)["IsAuthenticated"] && isSameSession()) {
        return true;
    } else {
        return false;
    }
};

export const isSameSession = function () {
    const localStorageClientId =  getSession("ClientID");
    let sessionStorageClientId = window.sessionStorage.getItem("ClientID");
    if(!sessionStorageClientId && localStorageClientId) {
        window.sessionStorage.setItem("ClientID", localStorageClientId)
        sessionStorageClientId = localStorageClientId;
    }
    if(localStorageClientId == sessionStorageClientId) {
        return true;
    } else {
        return false;
    }
}

export const resetSession = () => {
    localStorage.removeItem("MMClientVars");
    sessionStorage.removeItem("ClientID");
    localStorage.removeItem("dynamicSummaryFields");
    localStorage.removeItem("campaignViewId");
}

export const resetSessionStorage = () => {
    sessionStorage.removeItem("ClientID");
}

export const setSessionStorageValue = (key, value) => {
    window.sessionStorage.setItem(key, value);
}

export const getSessionStorageValue = (key) =>{
   return window.sessionStorage.getItem(key);
}

export const setSessionValue = (key, value) => {
    const existingSessionValues = localStorage.getItem("MMClientVars") ? localStorage.getItem("MMClientVars") : {};
    const obj = typeof (existingSessionValues) === "string" ? JSON.parse(existingSessionValues) : existingSessionValues;
    obj[key] = value;
    localStorage.setItem("MMClientVars", JSON.stringify(obj));
}

export const removeSession = (key) => {
    const sessionDetails = JSON.parse(localStorage.getItem("MMClientVars"));
    delete sessionDetails[key];
    localStorage.setItem("MMClientVars", JSON.stringify(sessionDetails));
}

export const getSession = (key) => {
    const clientDetails = window.localStorage.getItem("MMClientVars");
    if (clientDetails) {
        return JSON.parse(clientDetails)[key];
    } else {
        return "";
    }
}

