import React, { useRef, useEffect } from "react";
import { createRoot } from 'react-dom/client';
import PropTypes from "prop-types";

import Modal from "./Modal";

import "./ConfirmPopup.scss";
import { Page_ErrorHandler } from "../utilities/apiUrls";
import { openPage } from "../utilities/commonHelpers";

function ConfirmPopup(props) {
    const { title = "Alert", acceptText = "Yes", rejectText = "No", isSuccess="false", message, confirmResolve, popupContainer, isAlert, isInfo, children, style,RejectFunction,doNotShowAgain } = props;
    const acceptBtnRef = useRef();

    useEffect(() => {
        acceptBtnRef.current && acceptBtnRef.current.focus();
    }, []);

    const onAccept = () => {
        popupContainer.unmount();
        confirmResolve(true);
    };
    const onReject = () => {
        popupContainer.unmount();
        confirmResolve(false);
    };
    // handleReject is used for Functionality on Clicking No(different functionality compared to close icon )
    const handleReject=()=>{
        if(RejectFunction){
            RejectFunction()
        }
        onReject();
    }

    //This method will be called when we send acceptText = "Create Installments" from BillingContact.js file
    const createInstallments = () => {
        var val = document.getElementById('installmentsInput').value;
        popupContainer.unmount();
        confirmResolve(val);
    }

    return (
        <Modal title={title} onClose={onReject} style={style}>
            {children ? (
                children
            ) : (
                <div className="confirm-popup">
                    <div className="row pb-2">
                        <div className={`${isSuccess === true ? "success" : isAlert === true ? "alert" : isInfo ? "info" : "confirm" }-icon`} />
                        <div className="col confirm-message" dangerouslySetInnerHTML={{ __html: message }}></div>
                    </div>
                    {doNotShowAgain&&(<div className="disablePopUp mt-2">
                     <div className="doNot-Show"><input type="checkbox" className="doNot-ShowCheck" onChange={doNotShowAgain}/><span class="ml-2"><b>Do not show again?</b></span>
                    </div></div>)}
                    <div className="confirm-actions">
                        <button className="btn-primary" id="btnModelYes" ref={acceptBtnRef} onClick={acceptText === "Create Installments" ? createInstallments : onAccept}>
                            {acceptText}
                        </button>
                        {isAlert !== true && !isInfo && (
                            <button className="btn-primary ml-6" id="btnModelNo" onClick={handleReject}>
                                {rejectText}
                            </button>
                        )}
                    </div>
                </div>
            )}
        </Modal>
    );
}

ConfirmPopup.propTypes = {
    title: PropTypes.string,
    message: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
};

export function showConfirm(options) {
    return new Promise((confirmResolve) => {
        let popupContainer = document.querySelector("#confirm-popup");
        if (popupContainer === null) {
            popupContainer = document.createElement("div");
            popupContainer.id = "confirm-popup";
            document.querySelector("body").appendChild(popupContainer);
        }
        const root = createRoot(popupContainer);
        root.render(<ConfirmPopup {...options} confirmResolve={confirmResolve} popupContainer={root} />);
    });
}

export function showAlert(options) {
    return new Promise((confirmResolve) => {
        let popupContainer = document.querySelector("#confirm-popup");
        if (popupContainer === null) {
            popupContainer = document.createElement("div");
            popupContainer.id = "confirm-popup";
            document.querySelector("body").appendChild(popupContainer);
        }
        const root = createRoot(popupContainer);
        options.acceptText = options.acceptText === undefined ? "OK" : options.acceptText;
        root.render(<ConfirmPopup {...options} isAlert={true} confirmResolve={confirmResolve} popupContainer={root} />);
    });
}
export function showInfo(options) {
    return new Promise((confirmResolve) => {
        let popupContainer = document.querySelector("#confirm-popup");
        if (popupContainer === null) {
            popupContainer = document.createElement("div");
            popupContainer.id = "confirm-popup";
            document.querySelector("body").appendChild(popupContainer);
        }
        const root = createRoot(popupContainer);
        options.acceptText = options.acceptText === undefined ? "OK" : options.acceptText;
        root.render(<ConfirmPopup {...options} isInfo={true} confirmResolve={confirmResolve} popupContainer={root} />);
    });
}

export function showError(options) {
    const { title = "System Notification", ErrorID } = options;

    return new Promise((confirmResolve) => {
        let popupContainer = document.querySelector("#confirm-popup");
        if (popupContainer === null) {
            popupContainer = document.createElement("div");
            popupContainer.id = "confirm-popup";
            document.querySelector("body").appendChild(popupContainer);
        }
        openPage(`${Page_ErrorHandler}?ErrorID=${ErrorID}`, "Error Handler", false, false, true);
    });
}

export function showSuccess(options) {
    return new Promise((confirmResolve) => {
        let popupContainer = document.querySelector("#confirm-popup");
        if (popupContainer === null) {
            popupContainer = document.createElement("div");
            popupContainer.id = "confirm-popup";
            document.querySelector("body").appendChild(popupContainer);
        }
        const root = createRoot(popupContainer);
        options.acceptText = options.acceptText === undefined ? "OK" : options.acceptText;
        root.render(<ConfirmPopup {...options} isSuccess={true} isAlert={true} confirmResolve={confirmResolve} popupContainer={root} />);
    });
}

export default ConfirmPopup;
