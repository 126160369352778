import React, { Suspense, useLayoutEffect, useEffect, useState } from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import ErrorBoundary from "./shared/ErrorBoundary";
import { getSessionValue, getRelativeURL, openPage, isWindowTopAccessible } from "./utilities/commonHelpers";
import "./App.scss";
import { apiCall } from "./utilities/apiCall";
import { API_ADMIN_NAVIGATION_LOGS } from "./utilities/apiUrls";
import routes from "./routes";
import NotificationPopUp from "./components/Notifications/MmNotifactionsPopUP";
import { notificationsGetByHeight, notificationDetails } from "./components/Notifications/Services/NotificationService";
import { IoIosNotificationsOutline } from "react-icons/io";
import { handleMessage } from "./developmentHelper";
import Loading from "./shared/Loading";

export const getHeigthandWidthPopup = (widthProperty) => {
	let width = window.innerWidth;
	let height = window.innerHeight;
	if (widthProperty === 60) {
		return { x: width * 0.3, y: height * 0.25, popUpWidth: "40%", popUpHeigth: "50%" };
	} else if (widthProperty === 75) {
		return { x: width * 0.175, y: height * 0.15, popUpWidth: "65%", popUpHeigth: "70%" };
	} else {
		return { x: width * 0.1, y: height * 0.1, popUpWidth: "80%", popUpHeigth: "80%" };
	}
};
function App() {
	const [isLoaded, setIsLoaded] = useState(false);
	const [notificationsPage, setNotificationsPage] = useState();
	const [notificationSelectedPath, setNotificationSelectedPath] = useState();

	useLayoutEffect(() => {
		const isCERoute = window.location.pathname.startsWith(`/ui/ce/`);
		const isProposalsPath = window.location.pathname.startsWith(`/ui/customer/proposals/view`);
		const isReportSharePath = window.location.pathname.toLowerCase().startsWith(`/ui/reports/sharereport`);
        const isGatewayPage = window.location.pathname.startsWith(`/ui/ar/paywithsavedcards`);
        if (!getSessionValue("Email") && !isCERoute && !isProposalsPath && !isGatewayPage && !isReportSharePath) {
            window.location = `${window.location.origin}/Login.aspx`;
        }
	}, []);

	const isSaUser = getSessionValue("IsSA");
	const isAdmin = getSessionValue("IsAdmin");
	const pageList = getSessionValue("PageList");

	const [popupNotifications, setPopupNotifications] = useState([]);

	const fetchNotifications = async (pageName, admin, saUser) => {
		const res = await notificationDetails(pageName, admin, saUser);
		const { Status, List } = res.content;
		if (Status === "Success" && List) {
			const notificationsData = List.map((data) => {
				return {
					...data,
					popupProperties: getHeigthandWidthPopup(data.Width)
				};
			});
			setPopupNotifications(notificationsData);
		} else {
			setPopupNotifications([]);
		}
	};

	useEffect(() => {
		isLoaded && apiCall(API_ADMIN_NAVIGATION_LOGS, "POST", window.location.href);
		if (isLoaded) {
			// to check for notifications in enabled or not.
			const routeDisplay = routes.filter((route) => {
				return route.showNotification == true;
			});
			let query = routeDisplay.find((route) => {
				return (
					route.path.toLowerCase() ===
					getRelativeURL(window.location.pathname).replace("/ui", "").toLocaleLowerCase()
				);
			});
			setNotificationSelectedPath(query);
			if (query) {
				setNotificationsPage(query.description);
				let pagelist = pageList ? pageList.split(",") : [];
				let result = pagelist.findIndex((item) => query.description.toLowerCase() === item.toLowerCase());
				if (result != -1) {
					fetchNotifications(query.description, isAdmin, isSaUser);
				}
			}
		}
	}, [window.location.href, isLoaded]);

	useLayoutEffect(() => {
		// Stop the React page load until Message Localizer is loaded.
		// This is to get the ClientID & domain info without which page cannot be loaded successfully
		const loadMessageLocalizer = (urlPrefix = "") => {
			// Re-use the Client Message from Home Page when available
			if (!isWindowTopAccessible() || !window.top.hasOwnProperty("MMClientMessage")) {
				const script = document.createElement("script");
				script.src = `${urlPrefix}/intranet/localizer.js.axd?v=` + getSessionValue("ContentVersion");
				script.addEventListener("load", () => setIsLoaded(true));
				document.querySelector("body").appendChild(script);
			} else {
				setIsLoaded(true);
			}
		};
		const isNotBlankPage = window.location.pathname !== "/ui/blank";
		if (process.env.NODE_ENV === "development") {
			//Load the Helper frame to post webMethod API calls (Login is mandatory to the site in another tab)
			const body = document.querySelector("body");
			const iframe = document.createElement("iframe");
			let messageHandler;
			import("./developmentHelper").then(({ handleMessage, devURL, sessionValues }) => {
				messageHandler = handleMessage;
				//Set hard-coded session values to localStorage
				localStorage.setItem("MMClientVars", JSON.stringify(sessionValues));
				isNotBlankPage && loadMessageLocalizer(devURL);
				iframe.src = `${devURL}/intranet/Mirabel.MM.Web/Members/Home/ReactLocalHelper.aspx`;
				iframe.style.display = "none";
				iframe.id = "mmdeviframe";
				body.appendChild(iframe);
				window.addEventListener("message", messageHandler);
			});
			return () => {
				body.removeChild(iframe);
				window.removeEventListener("message", messageHandler);
			};
		} else {
			isNotBlankPage && loadMessageLocalizer();
		}
	}, []);

	const handleNotificationClick = () => {
		openPage(
			`${process.env.PUBLIC_URL}/mmnotification?toPage=create&fromPage=${notificationSelectedPath.description}`,
			"Notifications"
		);
	};
	return (
		<ErrorBoundary>
			{isLoaded ? (
				<Suspense fallback={<Loading />}>
					{isSaUser === true &&
						notificationsPage &&
						notificationsPage.toLowerCase() !== "notifications" &&
						notificationsPage.toLowerCase() !== "websitesetup" && (
							<span onClick={handleNotificationClick}>
								<IoIosNotificationsOutline
									className="pointer"
									style={{
										position: "absolute",
										right: "0px",
										height: "30px",
										width: "30px",
										zIndex: "1000",
										color: "green"
									}}
								/>
							</span>
						)}

					{popupNotifications && popupNotifications.length > 0 && (
						<NotificationPopUp
							popupNotifications={popupNotifications}
							notificationsPage={notificationsPage}
						/>
					)}
					<BrowserRouter basename="/ui">
						<Switch>
							{routes.map((route, index) => (
								<Route key={index} exact path={route.path} component={route.component} />
							))}
						</Switch>
					</BrowserRouter>
				</Suspense>
			) : (
				<Loading />
			)}
		</ErrorBoundary>
	);
}

export default App;
