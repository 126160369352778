export const ConfirmCompanyEdit = "CompanyUpdate";
export const MySavedSearchAndMyLists = "MySavedSearchAndMyLists";
export const AdvSearchViews = "AdvSearchViews";
export const OpportunityRestrictionMsg = "OpportunityRestrictionMsg";
export const DeleteConfirmation = "DeleteConfirmation";
export const ResendEmailConfirmation = "ResendEmailConfirmation";
export const UngroupConfirmation = "UngroupConfirmation";
export const OpportunityDeleteSuccessMsg = "OpportunityDeleteSuccessMsg";
export const OpportunityDeleteFailMsg = "OpportunityDeleteFailMsg";
export const OpportunityChangeToClosedWonMsg =
  "OpportunityChangeToClosedWonMsg";
export const PipelineStageID = "PipelineStageID";
export const SuccessfulSaveMessage = "SuccessfulSaveMessage";
export const SaveConfirmation = "SaveConfirmation";
export const StatusChangeToWon = "StatusChangeToWon";
export const StatusChangeToLost = "StatusChangeToLost";
export const StageChangeToClosedWonMsg = "StageChangeToClosedWonMsg";
export const StageChangeToClosedLostMsg = "StageChangeToClosedLostMsg";
export const ProbabilityChangeTo100 = "ProbabilityChangeTo100";
export const ProbabilityChangeTo0 = "ProbabilityChangeTo0";
export const WonProbability100 = "WonProbability100";
export const WonStage100 = "WonStage100";
export const LostProb0 = "LostProb0";
export const LostStage0 = "LostStage0";
export const OpenProbNot0 = "OpenProbNot0";
export const OpenProbNot100 = "OpenProbNot100";
export const OpenStageBtw0And100 = "OpenStageBtw0And100";
export const NoAccess = "NoAccess";
export const ReadOnly = "ReadOnly";
export const ProbEmpty = "ProbEmpty";
export const LostReasonDuringStatusOpen = "LostReasonDuringStatusOpen";
export const OppNameMandatory = "OppNameMandatory";
export const OppAmountMandatory = "OppAmountMandatory";
export const OppStatusMandatory = "OppStatusMandatory";
export const OppStageMandatory = "OppStageMandatory";
export const OppProbabilityMandatory = "OppProbabilityMandatory";
export const OppCloseDateMandatory = "OppCloseDateMandatory";
export const OppLostReasonMandatory = "OppLostReasonMandatory";
export const OppLostReasonNotesMandatory = "OppLostReasonNotesMandatory";
export const OppContactMandatory = "OppContactMandatory";
export const OppTypeMandatory = "OppTypeMandatory";
export const OppBusinessUnitMandatory = "OppBusinessUnitMandatory";
export const OppProductMandatory = "OppProductMandatory";
export const OppPrimaryCampaignSourceMandatory =
  "OppPrimaryCampaignSourceMandatory";
export const OppAssignedToMandatory = "OppAssignedToMandatory";
export const OppSalesPresenterMandatory = "OppSalesPresenterMandatory";
export const OppNextStepMandatory = "OppNextStepMandatory";
export const OppCannotMove = "OppCannotMove";
export const OPP_FILTER_CANNOT_SELECTED = "OPP_FILTER_CANNOT_SELECTED";
export const AD_NUMBER_USED = "AD_NUMBER_USED";
export const PICKUP_FROM_NOT_SELECTED = "PICKUP_FROM_NOT_SELECTED";
export const DELETE_PRODUCTION_CONTACT_MESSAGE =
  "DELETE_PRODUCTION_CONTACT_MESSAGE";
export const PRODUCTION_CONTACT_UPDATE_FAIL_MESSAGE =
  "PRODUCTION_CONTACT_UPDATE_FAIL_MESSAGE";
export const JOB_JACKET_STATUS_UPDATE_FAIL_MESSAGE =
  "JOB_JACKET_STATUS_UPDATE_FAIL_MESSAGE";
export const PRODUCTION_NOTE_NOT_EDITABLE = "PRODUCTION_NOTE_NOT_EDITABLE";
export const UNAUTHORIZED_TO_EDIT = "UNAUTHORIZED_TO_EDIT";
export const COMPPGVALUECONDITION = "COMPPGVALUECONDITION";
export const ALL_CONTACTS_UNDER_PRODUCTION_CONTACTS =
  "ALL_CONTACTS_UNDER_PRODUCTION_CONTACTS";
export const SELECT_CUSTOMER_LINK = "SELECT_CUSTOMER_LINK";
export const OPP_PROPOSAL_SELECT = "OPP_PROPOSAL_SELECT";
export const OPP_PROPOSAL_CONVERTED_TO_CONTRACT =
  "OPP_PROPOSAL_CONVERTED_TO_CONTRACT";
export const OPP_PROPOSAL_PRODUCT_CHANGE = "OPP_PROPOSAL_PRODUCT_CHANGE";
export const Barter_Cannot_Exceed_Net = "Barter_Cannot_Exceed_Net";
export const Adjustment_Mandatory = "Adjustment_Mandatory";
export const Adjustment_Amount_NonZero = "Adjustment_Amount_NonZero";
export const Note_Mandatory = "Note_Mandatory";
export const Rate_Cannot_Lessthan_Zero = "Rate_Cannot_Lessthan_Zero";
export const UpdatedRate_Cannot_Exceed_CurrentRate =
  "UpdatedRate_Cannot_Exceed_CurrentRate";
export const Agency_Discount_Should_Greaterthan_Zero =
  "Agency_Discount_Should_Greaterthan_Zero";
export const Valid_AgencyDiscount_Percentege =
  "Valid_AgencyDiscount_Percentege";
export const Startdate_Cannot_Exceed_Enddate =
  "Startdate_Cannot_Exceed_Enddate";
export const Cpm_Title = "Cpm_Title";
export const Cpm_Value_Not_Found = "Cpm_Value_Not_Found";
export const Price_Title = "Price_Title";
export const Price_Value_Not_Found_For_Rate_Card =
  "Price_Value_Not_Found_For_Rate_Card";
export const CardRate_Title_Enter_Rate = "CardRate_Title_Enter_Rate";
export const CardRate_Value_Not_Found_For_Rate_Card =
  "CardRate_Value_Not_Found_For_Rate_Card";
export const Adjustments_Rate_Reset_Value = "Adjustments_Rate_Reset_Value";
export const RateCard_Not_Have_Adsize_Value = "RateCard_Not_Have_Adsize_Value";
export const No_Issue_Selected = "No_Issue_Selected";
export const Issues_Not_Found = "Issues_Not_Found";
export const CardRate_Net_Gross_Values_Recheck =
  "CardRate_Net_Gross_Values_Recheck";
export const Tax_Selection_Info = "Tax_Selection_Info";
export const Rate_Discount_Name = "Rate_Discount_Name";
export const Rate_Charge_Name = "Rate_Charge_Name";
const ClosedIssueCheckFailed = "ClosedIssueCheckFailed";
const PROPOSALNOTEXISTS = "PROPOSALNOTEXISTS";
const INVALIDCONTACT = "INVALIDCONTACT ";
export const INVOICEALREADYCREATED = "INVOICEALREADYCREATED";
const INVALIDDATERANGES = "INVALIDDATERANGES";
export const GO_TO_ORDER = "GO_TO_ORDER";
export const DuplicateProposalTemplateLineItem =
  "DUPLICATEPROPOSALTEMPLATELINEITEM";
export const Adjustments_Removed = "Adjustments_Removed";
export const No_Rate_Found = "No_Rate_Found";
export const No_Rate_Adjustment_Removed = "No_Rate_Adjustment_Removed";
export const Details = "Details";
export const Pricing = "Pricing";
export const Schedule = "Schedule";
export const StartDate = "StartDate";
export const EndDate = "EndDate";
export const Names = "Names";
export const SalesRep = "SalesRep";
export const BillingContact = "BillingContact";
export const AdditionalProducts = "AdditionalProducts";
export const WarningsandAlerts = "WarningsandAlerts";
export const WarningsAndAlertsMessage = "WarningsAndAlertsMessage";
export const Percentage = "Percentage";
export const Sales_Rep_Error = "Sales_Rep_Error";
export const Order_Deleted = "Order_Deleted";
export const Start_Date_Not_Entered = "";
export const Select_Adjustment = "Select_Adjustment";
export const Adjust_Rate = "Adjust_Rate";
export const Adjustment_Amount = "Adjustment_Amount";
export const New_Rate = "New_Rate";
export const Notes = "Notes";
export const Multiple_Products_Text = "Multiple_Products_Text";
export const Back_Button = "Back_Button";
export const Next_Button = "Next_Button";
export const Save_Button = "Save_Button";
export const Default_Billing_Contact_Text = "Default_Billing_Contact_Text";
export const Remove_Order_From_Payment_Plan = "Remove_Order_From_Payment_Plan";
export const Go_To_Payment_Plan = "Go_To_Payment_Plan";
export const PAYMENT_PLAN_NUMBER = "PAYMENT_PLAN_NUMBER";
export const BILL_TOTAL = "BILL_TOTAL";
export const ACTUAL_TOTAL = "ACTUAL_TOTAL";
export const PAYMENT_TOTAL = "PAYMENT_TOTAL";
export const Date = "Date";
export const Amount = "Amount";
export const Net = "Net";
export const Gross = "Gross";
export const DURATION_NEEDED = "DURATION_NEEDED";
export const PERCENTAGE_OR_AMOUNT_NEEDED = "PERCENTAGE_OR_AMOUNT_NEEDED";
export const VALID_TILL_LESS_THAN_CURRENT = "VALID_TILL_LESS_THAN_CURRENT";
export const COUPON_NAME_MANDATORY = "COUPON_NAME_MANDATORY";
export const COUPON_CODE_MANDATORY = "COUPON_CODE_MANDATORY";

export const COUPON_CODE_ALREADY_EXIST = "COUPON_CODE_ALREADY_EXIST";
export const COUPON_DELETE_ALERT = "COUPON_DELETE_ALERT";
export const REDEMPTIONS_PER_CUSTOMER_CANNOT_EXCEED_MAXREDEMPTIONS =
  "REDEMPTIONS_PER_CUSTOMER_CANNOT_EXCEED_MAXREDEMPTIONS";
export const MAXREDEMPTIONS_CANNOT_LESS_THAN_REDEMPTIONS =
  "MAXREDEMPTIONS_CANNOT_LESS_THAN_REDEMPTIONS";
export const PLAN_OR_ADDON_REQUIRED = "PLAN_OR_ADDON_REQUIRED";
export const CANNOT_DELETE_PLAN = "CANNOT_DELETE_PLAN";
export const PLAN_DELETED = "PLAN_DELETED";
export const DeleteOrdeConfirmationMessage = "DeleteOrdeConfirmationMessage";
export const SelectInvoiceWarning = "SelectInvoiceWarning";
export const SelectSameCustomerWarning = "SelectSameCustomerWarning";
export const ChangeEmailWarning = "ChangeEmailWarning";
export const ValidEmailAlert = "ValidEmailAlert";
export const NoOrdersToDisplayMessage = "NoOrdersToDisplayMessage";
export const NoTasksToDisplayMessage = "NoTasksToDisplayMessage";
export const NoOrderHistoryToDisplayMessage = "NoOrderHistoryToDisplayMessage";
export const NoInvoicesToDisplayMessage = "NoInvoicesToDisplayMessage";
export const NoBillsToDisplayMessage = "NoBillsToDisplayMessage";
export const NoBillingHistoryToDisplayMessage =
  "NoBillingHistoryToDisplayMessage";
export const NoSubscriptionsToDisplayMessage =
  "NoSubscriptionsToDisplayMessage";
export const CanNotDelteInvoicedOrderMessage =
  "CanNotDelteInvoicedOrderMessage";
export const PasswordExpired = "PasswordExpired";
export const PasswordNotStrong = "PasswordNotStrong";
export const CpVerificationNotCompleted = "CpVerificationNotCompleted";
export const EmailAddress = "EmailAddress";
export const Email = "Email";
export const OnlineSubscriptionReplyEmail = "OnlineSubscriptionReplyEmail";
export const OnlineSubcscriptionConfirmationEmail =
  "OnlineSubcscriptionConfirmationEmail";
export const ProductionNotes = "ProductionNotes";
export const CreateNotes = "CreateNotes";
export const DeleteNoteConfirmation = "DeleteNoteConfirmation";
export const SendApproval = "SendApproval";
export const NoCustomersFoundForCP = "NoCustomersFoundForCP";
export const PartialPaymentMessage = "PartialPaymentMessage";
export const FromDateCanNotExceedToDate = "FromDateCanNotExceedToDate";
export const END_UNIT_VALIDATION = "END_UNIT_VALIDATION";
export const PLAN_ADDON_REQUIRED = "PLAN_ADDON_REQUIRED";
export const CONFLICT_MESSAGE = "CONFLICT_MESSAGE";
export const ADVERTISER_UNMAP_CONFIRMATION = "ADVERTISER_UNMAP_CONFIRMATION";
export const ADVERTISER_MAP_CONFIRMATION = "ADVERTISER_MAP_CONFIRMATION";
export const GAM_ADVERTISER_MAP_CONFIRMATION =
  "GAM_ADVERTISER_MAP_CONFIRMATION";
export const SELECT_ORDERS = "SELECT_ORDERS";
export const CANNOT_SELECT_ORDER = "CANNOT_SELECT_ORDER";
export const CANNOT_SELECT_ADCELLERANT_ORDER =
  "CANNOT_SELECT_ADCELLERANT_ORDER";
export const EMAIL_LIMIT = "EMAIL_LIMIT";
export const REMOVE_BILLINGCONTACT_CONFIRMATION =
  "REMOVE_BILLINGCONTACT_CONFIRMATION";
export const PRIMARY_CONTACT_CONVERSION_SUCCESS =
  "PRIMARY_CONTACT_CONVERSION_SUCCESS";
export const DELETE_AGENCY_CONFIRMATION = "DELETE_AGENCY_CONFIRMATION";
export const ADVERTISER_SELECT = "ADVERTISER_SELECT";
export const CANNOT_SELECT_INVOICED = "CANNOT_SELECT_INVOICED";
export const CANNOT_SELECT_ADCELLERANT = "CANNOT_SELECT_ADCELLERANT";
export const RESET_ALERT = "RESET_ALERT";
export const ADDON_NAME_MANDATORY = "ADDON_NAME_MANDATORY";
export const ADDON_CODE_MANDATORY = "ADDON_CODE_MANDATORY ";
export const PLEASE_SELECT_COUPONS = "PLEASE_SELECT_COUPONS";
export const PLEASE_SELECT_ADDONS = "PLEASE_SELECT_ADDONS";
export const PLEASE_SELECT_PLANS = "PLEASE_SELECT_PLANS";
export const ADDON_CONFLICT = "ADDON_CONFLICT";
export const PLEASE_SELECT_PRODUCT = "PLEASE_SELECT_PRODUCT";
export const SPECIFY_PRICE_FOR_INFINITE_RANGE =
  "SPECIFY_PRICE_FOR_INFINITE_RANGE";
export const MAX_COUPONS = "MAX_COUPONS";
export const PERCENTAGE_COUPONS_LIMIT = "PERCENTAGE_COUPONS_LIMIT";
export const AMOUNT_COUPONS_LIMIT = "AMOUNT_COUPONS_LIMIT";
export const SELECT_START_DATE = "SELECT_START_DATE";
export const SELECT_GIFTER_EMAIL = "SELECT_GIFTER_EMAIL";
export const PREVIOUS_DATE_SELECTED = "PREVIOUS_DATE_SELECTED";
export const CONTACT_NOT_SELECTED = "CONTACT_NOT_SELECTED";
export const GIFTER_CONTACT_NOT_SELECTED = "GIFTER_CONTACT_NOT_SELECTED";

export const BILLING_CYCLES_SHOULD_BE_GREATER_THAN_ZERO =
  "BILLING_CYCLES_SHOULD_BE_GREATER_THAN_ZERO";
// export const QUANTITY_SHOULD_BE_GREATER_THAN_ZERO = "QUANTITY_SHOULD_BE_GREATER_THAN_ZERO";
export const CANNOT_DELETE_INVOICED_ORDERS = "CANNOT_DELETE_INVOICED_ORDERS";
export const CANNOT_DELETE_CLOSED_ISSUE_ORDERS =
  "CANNOT_DELETE_CLOSED_ISSUE_ORDERS";
export const EMAIL_BODY_MANDATORY = "EMAIL_BODY_MANDATORY";
export const EMAIL_SUBJECT_MANDATORY = "EMAIL_SUBJECT_MANDATORY";
export const CAMPAIGN_NAME_MANDATORY = "CAMPAIGN_NAME_MANDATORY";
export const SEND_EMAIL_CONFIRMATION = "SEND_EMAIL_CONFIRMATION";
export const CANCEL_CAMPAIGN_CONFIRMATION = "CANCEL_CAMPAIGN_CONFIRMATION";
export const GMAIL_SEND_DISABLED = "GMAIL_SEND_DISABLED";
export const SEND_CRM_EMAIL_FAILED = "SEND_CRM_EMAIL_FAILED";
export const CANCEL_CRM_EMAIL_FAILED = "CANCEL_CRM_EMAIL_FAILED";
export const CUSTOMER_NO_EMAILS = "CUSTOMER_NO_EMAILS";
export const PLEASE_PROVIDE_FROM_EMAIL = "PLEASE_PROVIDE_FROM_EMAIL";
export const PLEASE_PROVIDE_SCHEDULE_TIME = "PLEASE_PROVIDE_SCHEDULE_TIME";
export const FROM_EMAIL_REQUIRED = "FROM_EMAIL_REQUIRED";
export const DEFAULT_FROM_EMAIL_REQUIRED = "DEFAULT_FROM_EMAIL_REQUIRED";
export const FROM_NAME_REQUIRED = "FROM_NAME_REQUIRED";
export const DEFAULT_FROM_NAME_REQUIRED = "DEFAULT_FROM_NAME_REQUIRED";
export const CAMPAIGN_REQUIRED = "CAMPAIGN_REQUIRED";
export const CAMPAIGN_TYPE_REQUIRED = "CAMPAIGN_TYPE_REQUIRED";
export const SEND_EMAIL = "SEND_EMAIL";
export const BATCH_UPDATE = "BATCH_UPDATE";

export const TAX_NAME_MANDATORY = "TAX_NAME_MANDATORY";
export const TAX_GROUP_NAME_MANDATORY = "TAX_GROUP_NAME_MANDATORY";
export const TAX_GROUP_DUPLICATE = "TAX_GROUP_DUPLICATE";
export const TAX_TYPE_MANDATORY = "TAX_TYPE_MANDATORY";
export const TAX_AMOUNT_PERCENTAGE_EMPTY_CHECK =
  "TAX_AMOUNT_PERCENTAGE_EMPTY_CHECK";
export const TAX_DELETE_ALERT = "TAX_DELETE_ALERT";
export const TAXES_DELETE_CONFIRM = "TAXES_DELETE_CONFIRM";
export const TAXES_QB_MAPPING = "TAXES_QB_MAPPING";
export const TAXGROUPS_QB_MAPPING = "TAXGROUPS_QB_MAPPING";
export const TAX_CONFLICT_NAME = "TAX_CONFLICT_NAME";
export const ACTIVE_TAX_IN_TAXGROUPS = "ACTIVE_TAX_IN_TAXGROUPS";

export const CANCELREASON_NAME_MANDATORY = "CANCELREASON_NAME_MANDATORY";
export const CANCELREASON_DELETE_ALERT = "CANCELREASON_DELETE_ALERT";
export const CANCELREASON_CONFLICT_NAME = "CANCELREASON_CONFLICT_NAME";

export const NAME_REQUIRED = "NAME_REQUIRED";
export const DATE_REQUIRED = "DATE_REQUIRED";
export const EMAIL_SENT = "EMAIL_SENT";
//Subscription Source
export const SOURCE_NAME_MANDATORY = "SOURCE NAME MANDATORY";
export const SOURCE_DELETE_ALERT = "SOURCE_DELETE_ALERT";
export const SOURCE_CONFLICT_NAME = "SOURCE_CONFLICT_NAME";
export const DELETE_SOURCE_REQUIRES_SOURCEID =
  "DELETE_SOURCE_REQUIRES_SOURCEID";

//Invoice Create
export const INVOICE_TEMPLATE_MANDATORY = "INVOICE_TEMPLATE_MANDATORY";
export const ATLEAST_ONEITEM_SELECTED = "ATLEAST_ONEITEM_SELECTED";
export const DUEDATE_MANDATORY = "DUEDATE_MANDATORY";
export const INVOICEDATE_MANDATORY = "INVOICEDATE_MANDATORY";
export const SALESREP_MANDATORY = "SALESREP_MANDATORY";
export const CASHAMOUNT_MANDATORY = "CASHAMOUNT_MANDATORY";
export const TAXES_DESCRIPTION = "TAXES_DESCRIPTION";
export const TAX_DELETE_CONFLICT = "TAX_DELETE_CONFLICT";
export const CANNOT_EDIT_TAXES = "CANNOT_EDIT_TAXES";
export const CANNOT_DELETE_TAXES = "CANNOT_DELETE_TAXES";
export const CANNOT_EDIT_TAXGROUPS = "CANNOT_EDIT_TAXGROUPS";
export const CANNOT_DELETE_TAXGROUPS = "CANNOT_DELETE_TAXGROUPS";
export const TAX_GROUP_DELETE_CONFLICT = "TAX_GROUP_DELETE_CONFLICT";
export const DELETE_SUBSCRIPTION = "DELETE_SUBSCRIPTION";
export const INVOICE_SEARCH_SELECT = "INVOICE_SEARCH_SELECT";

export const DASHBOARD_ALL_NOTES_ACTIVITIES = "DASHBOARD_ALL_NOTES_ACTIVITIES";
export const DASHBOARD_ACTIVITY_HELP = "DASHBOARD_ACTIVITY_HELP";
export const DASHBOARD_RELOAD = "DASHBOARD_RELOAD";
export const DASHBOARD_TODAYS_CALLBACKS = "DASHBOARD_TODAYS_CALLBACKS";
export const DASHBOARD_TODAYS_CALLS_MEETINGS =
  "DASHBOARD_TODAYS_CALLS_MEETINGS";
export const DASHBOARD_CALLS_MEETINGS_TITLE = "DASHBOARD_CALLS_MEETINGS_TITLE";
export const DASHBOARD_QUICK_LINKS = "DASHBOARD_QUICK_LINKS";
export const DASHBOARD_OPPORTUNITIES = "DASHBOARD_OPPORTUNITIES";
export const DASHBOARD_YEAR_TO_DATE_BOOKINGS =
  "DASHBOARD_YEAR_TO_DATE_BOOKINGS";
export const DASHBOARD_YEAR_TO_DATE_ACTIVITIES =
  "DASHBOARD_YEAR_TO_DATE_ACTIVITIES";
export const DASHBOARD_OPPORTUNITY_TITLE = "DASHBOARD_OPPORTUNITY_TITLE";
export const DASHBOARD_OPPORTUNITY_HELP = "DASHBOARD_OPPORTUNITY_HELP";
export const DASHBOARD_YTDACTIVITY_HELP = "DASHBOARD_YTDACTIVITY_HELP";
export const DASHBOARD_MORE = "DASHBOARD_YTDACTIVITY_MORE";
export const DASHBOARD_YTDACTIVITY_TITLE = "DASHBOARD_YTDACTIVITY_TITLE";
export const DASHBOARD_ALLYTDACTIVITIES = "DASHBOARD_ALLYTDACTIVITIES";
export const DASHBOARD_YTDBOOKING_HELP = "DASHBOARD_YTDBOOKING_HELP";
export const DASHBOARD_ALLYTDBOOKINGS = "DASHBOARD_ALLYTDBOOKINGS";
export const DASHBOARD_CALENDAR = "DASHBOARD_CALENDAR";
export const DASHBOARD_NO_OPPORTUNITY = "DASHBOARD_NO_OPPORTUNITY";
export const DASHBOARD_NO_CALLS_MEETINGS = "DASHBOARD_NO_CALLS_MEETINGS";
export const TAX_NOT_SELECTED = "TAX_NOT_SELECTED";
export const CANNOT_VOID_LOCKED_INVOICE = "CANNOT_VOID_LOCKED_INVOICE";
export const DELETE_TEMPLATE_INFO = "DELETE_TEMPLATE_INFO";
export const MSG_ARREPORT_HAVEOPENPOSTDATEDIINVOICES =
  "MSG_ARREPORT_HAVEOPENPOSTDATEDIINVOICES";
export const MSG_ARREPORT_HAVEOPENPOSTDATEPAYMENTS =
  "MSG_ARREPORT_HAVEOPENPOSTDATEPAYMENTS";
export const MSG_PAYMENT_CREDIT_DEBIT_WITHOUT_QB =
  "MSG_PAYMENT_CREDIT_DEBIT_WITHOUT_QB";
export const MSG_PAYMENT_CREDIT_DEBIT_WITH_QB =
  "MSG_PAYMENT_CREDIT_DEBIT_WITH_QB";
export const MSG_ARREPORT_HAVECLOSEDPREPAYSINPOSTDATEPAYMENTS =
  "MSG_ARREPORT_HAVECLOSEDPREPAYSINPOSTDATEPAYMENTS";
export const ORDER_SEARCH_GANTT_VIEW_NO_DATA_MESSAGE =
  "ORDER_SEARCH_GANTT_VIEW_NO_DATA_MESSAGE";
export const PAGE_TYPE_PRODUCTION_MODULE = "productionmodule";
export const SHARE_REPORTS = "sharereports";
export const PAGE_TYPE_OPPORTUNITY_MODULE = "opportunitymodule";
export const PAGE_TYPE_SALES_MODULE = "salesmodule";
export const PAGE_TYPE_SEND_FOR_APPROVAL = "productionmoduleapproval";
export const PAGE_TYPE_PROPOSAL_APPROVAL = "proposalapproval";
export const PAGE_TYPE_PROPOSAL_APPROVAL_STAGE = "approvalstage";
export const PAGE_TYPE_SUBSCRIPTION_MODULE = "subscriptionletter";
export const DELETE_STAGE_HAS_DEPENDENCY = "DELETE_STAGE_HAS_DEPENDENCY";
export const DELETE_STAGE_REQUIRES_STAGEID = "DELETE_STAGE_REQUIRES_STAGEID";
export const DELETE_LEAD_TYPE_REQUIRES_LEADTYPEID =
  "DELETE_LEAD_TYPE_REQUIRES_LEADTYPEID";
export const UPDATE_STAGE_IN_ORDER = "UPDATE_STAGE_IN_ORDER";
export const REMOVE_STAGE_IN_ORDER = "REMOVE_STAGE_IN_ORDER";
export const ORDER_STAGE_NAME_REQUIRED = "ORDER_STAGE_NAME_REQUIRED";
export const ORDER_STAGE_NAME_AND_PRODUCT_TYPE_REQUIRED =
  "ORDER_STAGE_NAME_AND_PRODUCT_TYPE_REQUIRED";
export const ORDER_STAGE_PRODUCT_TYPE_REQUIRED =
  "ORDER_STAGE_PRODUCT_TYPE_REQUIRED";
export const DUPLICATE_ORDER_STAGE_NAME = "DUPLICATE_ORDER_STAGE_NAME";
export const DUPLICATE_PRODUCT_NAME = "DUPLICATE_PRODUCT_NAME";
export const DUPLICATE_TASK_LABEL = "DUPLICATE_TASK_LABEL";
export const PRODUCTTYPE_CANNOT_UPDATED = "PRODUCTTYPE_CANNOT_UPDATED";
export const DUPLICATE_PROSPECTING_STAGE_NAME =
  "DUPLICATE_PROSPECTING_STAGE_NAME";
export const DUPLICATE_LEAD_TYPE_VALUE = "DUPLICATE_LEAD_TYPE_VALUE";
export const DUPLICATE_LEAD_STATUS_VALUE = "DUPLICATE_LEAD_STATUS_VALUE";
export const DUPLICATE_LEAD_SOURCE_VALUE = "DUPLICATE_LEAD_SOURCE_VALUE";
export const DELETE_PROSPECTING_STAGE_HAS_DEPENDENCY =
  "DELETE_PROSPECTING_STAGE_HAS_DEPENDENCY";
export const DELETE_LEAD_TYPE_HAS_DEPENDENCY =
  "DELETE_LEAD_TYPE_HAS_DEPENDENCY";
export const DELETE_LEAD_STATUS_HAS_DEPENDENCY =
  "DELETE_LEAD_STATUS_HAS_DEPENDENCY";
export const DELETE_LEAD_SOURCE_HAS_DEPENDENCY =
  "DELETE_LEAD_SOURCE_HAS_DEPENDENCY";
export const PRODUCTION_FIELD_VALUE_EMPTY = "PRODUCTION_FIELD_VALUE_EMPTY";
export const RESET_AD_NUMBER_CONFIRMATION_MESSAGE =
  "Are you sure you want to remove the Job #?";
export const DEFAULT_JOB_CONFIRMATION_MESSAGE =
  "This will overwrite any existing job numbers, are you sure you want to continue? This action cannot be undone.";
export const DELETE_TASK_CONFIRMATION_MESSAGE =
  "This will delete selected tasks, are you sure you want to continue? This action cannot be undone.";
export const DELETE_TASK_ONLY_ORDERS_SELECTED =
  "Delete action can only be performed on tasks. Please select tasks to delete.";
export const BATCH_UPDATE_OPTION_EMPTY = "BATCH_UPDATE_OPTION_EMPTY";
export const INVALID_REQUEST = "INVALID_REQUEST";
export const NoMapAddontoCoupon = "NoMapAddontoCoupon";
export const NoMapPlantoCoupon = "NoMapPlantoCoupon";

export const Msg_ValidationOnContractsForSameContact =
  "Msg_ValidationOnContractsForSameContact";
export const Msg_ValidationOnContractsForSameIssue =
  "Msg_ValidationOnContractsForSameIssue";
export const IssueChangeIsOnlyForIssueBasedOrders =
  "IssueChangeIsOnlyForIssueBasedOrders";
export const MsgCnf_UpdateOrders = "MsgCnf_UpdateOrders";
export const Msg_ProceedInvCheckOrders = "Msg_ProceedInvCheckOrders";
export const Msg_PleaseSelectIssueToChange = "Msg_PleaseSelectIssueToChange";
export const ORDER_NUMBER_CANNOT_BE_EMPTY = "ORDER_NUMBER_CANNOT_BE_EMPTY";
export const PRODUCTION_CREATE_NEW_STAGE_MSG =
  "No stages available to add to the view, please click 'add' button to create stages and refresh button to reload the stages.";

//Proposal - START
export const SUBJECT = "SUBJECT";
export const DESCRIPTION = "DESCRIPTION";
export const FILENAME = "FILENAME";
export const EMAIL = "EMAIL";
export const NAME = "NAME";
export const SEND = "SEND";
export const HISTORY = "HISTORY";
export const CLOSE = "CLOSE";
export const INVALID_DATA = "INVALID_DATA";
export const INAVLID_POPOSAL_ID = "INAVLID_POPOSAL_ID";
export const INAVLID_SALES_CONTACT_ID = "INAVLID_SALES_CONTACT_ID";
export const SUCCESS = "SUCCESS";
export const PROPOSAL_LINE_ITEMS_MOVE_SUCCESS =
  "PROPOSAL_LINE_ITEMS_MOVE_SUCCESS";
export const PROPOSAL_LINE_ITEMS_COPY_SUCCESS =
  "PROPOSAL_LINE_ITEMS_COPY_SUCCESS";

//Proposal Esign - START
export const ESIGN = "E-Sign";
export const ESIGN_CUSTOMER = "ESIGN_CUSTOMER";
export const ESIGN_CUSTOMER_AND_REP = "ESIGN_CUSTOMER_AND_REP";
export const ESIGN_VIEW_DOCUMENT_HISTORY = "ESIGN_VIEW_DOCUMENT_HISTORY";
export const ESIGN_FOR_CUSTOMER = "ESIGN_FOR_CUSTOMER";
export const ESIGN_FOR_CUSTOMER_AND_REP = "ESIGN_FOR_CUSTOMER_AND_REP";
export const ESIGN_CUSTOMER_SIGNER = "ESIGN_CUSTOMER_SIGNER";
export const ESIGN_REP = "ESIGN_REP";
export const ESIGNATURE_CHARGES_TEXT = "ESIGNATURE_CHARGES_TEXT";
export const EMPTY_SUBJECT = "EMPTY_SUBJECT";
export const EMPTY_DESCIPTION = "EMPTY_DESCIPTION";
export const EMPTY_FILE_NAME = "EMPTY_FILE_NAME";
export const FILE_NAME_ALREADY_EXISTS = "FILE_NAME_ALREADY_EXISTS";
export const EMPTY_CC_EMAIL = "EMPTY_CC_EMAIL";
export const EMPTY_CC_NAME = "EMPTY_CC_NAME";
export const EMPTY_SIGNER_EMAIL = "EMPTY_SIGNER_EMAIL";
export const EMPTY_SIGNER_NAME = "EMPTY_SIGNER_NAME";
export const INVALID_SIGNER_EMAIL = "INVALID_SIGNER_EMAIL";
export const INVALID_CC_EMAIL = "INVALID_CC_EMAIL";
export const ESIGN_CUSTOMER_AND_REP_SAME_EMAIL =
  "ESIGN_CUSTOMER_AND_REP_SAME_EMAIL";
export const SENTFORESIGN = "SENTFORESIGN";
export const ESIGNATURE_STATUS = "ESIGNATURE_STATUS";
export const ESIGN_NO_DOC_HISTORY = "ESIGN_NO_DOC_HISTORY";
export const COMPLETE = "COMPLETE";
export const PENDING = "PENDING";
export const CANCELLED = "CANCELLED";
export const EXPIRED = "EXPIRED";
export const SIGNED_BY_ONE_PARTY = "SIGNED_BY_ONE_PARTY";
export const ESIGN_CON_MSG_TRASH_DOC = "ESIGN_CON_MSG_TRASH_DOC";
export const INVALID_FILENAME = "INVALID_FILENAME";
export const INACTIVATE_CONTACT_MESSAGE = "INACTIVATE_CONTACT_MESSAGE";
export const REACTIVATE_CONTACT_MESSAGE = "REACTIVATE_CONTACT_MESSAGE";
export const INACTIVATE_INFO_MESSAGE = "INACTIVATE_INFO_MESSAGE";
export const INACTIVATE_INFO_MESSAGE_BATCHUPDATE =
  "INACTIVATE_INFO_MESSAGE_BATCHUPDATE";
export const INACTIVE_CONTACT_NOTE = "INACTIVE_CONTACT_NOTE";
export const UPDATE_PROPOSAL_TEMPLATE = "UPDATE_PROPOSAL_TEMPLATE";
export const UPDATE_CHANGEORDER_TEMPLATE = "UPDATE_CHANGEORDER_TEMPLATE";
export const PROPOSAL_TEMPLATE_UPDATED_SUCCESSFULLY =
  "PROPOSAL_TEMPLATE_UPDATED_SUCCESSFULLY";
//Proposal Esign - END
//Proposal - END

//Report Share Start
export const REPORT_SHARE_CATEGORY_NAME = "reportsharecoverletter";
export const DEFAULT_REPORT_SHARE_TEMPLATE_NAME = "reportsharetemplate";
//Report Share End

export const Adjustment_AddORRate = "Adjustment_AddORRate";

//Non Admin Access Denied message
export const UNAUTHORIZED_ACCESS =
  "Access Denied. You are not authorized to access this.";

//DigitalAds - start
export const AUTH_ID_CONFLICT = "AUTH_ID_CONFLICT";
//DigitalAds- END

//Change Orders - Start
export const CHANGEORDER_EXISTS = "ChangeOrderExists";
export const CHANGEORDER_CLOSED_ISSUE_EXISTS = "ClosedIssueOrderExists";
export const CHANGEORDER_INVOICE_EXISTS = "InvoiceExists";
export const CHANGEORDER_DIFFERENT_CUSTOMERS = "DifferentCustomers";
export const CHANGEORDER_DIFFERENT_PRODUCTTYPES = "DifferentProductTypes";
export const CHANGEORDER_CREATED = "ChangeOrderCreated";
export const CHANGEORDER_ADDED = "ChangeOrderAdded";
export const CHANGEORDER_NOT_PENDING = "ChangeOrderNotPending";
export const CHANGEORDER_NOT_EXISTS = "ChangeOrderNotExists";
export const CHANGEORDER_ORDER_DELETED = "OneOfTheOriginalOrderDeleted";
export const CHANGEORDER_DIFFERENT_RATECARD_TYPES = "DifferentRateCardTypes";
export const CHANGEORDER_USER_NOT_AUTHORIZED_TO_CREATE_CHANGEORDER =
  "UserNotAuthorizedToCreateChangeOrder";
export const CHANGEORDER_USER_NOT_AUTHORIZED_TO_APPLY_CHANGEORDER =
  "UserNotAuthorizedToApplyChangeOrder";
export const FORMULA_RAN_WITHOUT_ERROR_INFO = "FormulaRanWithoutErrorInfo";
export const FORMULA_CAUSED_ERROR_INFO = "FormulaCausedErrorInfo";

//Change Orders - End

// Ad Master start

export const DEPENDENCY_AD_MASTER = "DependencyDataExist";

// Ad Master end

export const getDisplayText = (key) => {
  switch (key) {
    case MySavedSearchAndMyLists:
      return "My Saved Searches & Lists";
    case AdvSearchViews:
      return "Views";
    case EmailAddress:
      return "Company Email Address";
    case Email:
      return "Email Address";
    case OnlineSubscriptionReplyEmail:
      return "Reply Email Address";
    case OnlineSubcscriptionConfirmationEmail:
      return "Confirmation Email Address";
    case PipelineStageID:
      return "PipelineStageID";
    case ProductionNotes:
      return "Upload Notes & Files";
    case CreateNotes:
      return "Create Notes";
    case SendApproval:
      return "Send Approval";
    default:
  }
};
export const globalMessages = (messageType) => {
  switch (messageType) {
    case ConfirmCompanyEdit:
      return "The company name for this contact has been changed this could affect sales reports, accounts receivable reports & invoices, billing contacts, etc. Are you sure you want to update the company name?";
    case OpportunityRestrictionMsg:
      return "You have restricted access to the opportunity, need write access to create or update or delete the opportunity.";
    case DeleteConfirmation:
      return "Are you sure, You want to delete?";
    case DeleteNoteConfirmation:
      return "Are you sure you want to delete?  This will delete the attached file(s) as well.";
    case ResendEmailConfirmation:
      return "Are you sure, You want to Resend the Email?";
    case UngroupConfirmation:
      return "Are you sure, You want to Ungroup?";
    case OpportunityDeleteSuccessMsg:
      return "Successfully deleted the opportunity!";
    case OpportunityDeleteFailMsg:
      return "Failed to delete the opportunity.";
    case OpportunityChangeToClosedWonMsg:
      return "Are you sure, you want to change the stage to Closed Won?";
    case SuccessfulSaveMessage:
      return "Successfully saved the record!";
    case SaveConfirmation:
      return "Are you sure, you want to save?";
    case StatusChangeToWon:
      return (
        "Changing the Status to 'Won' will change the Stage " +
        "to 'Closed Won' and Probability to 100%. Please confirm"
      );
    case StatusChangeToLost:
      return (
        "Changing the Status to 'Lost' will change " +
        "the Stage to 'Closed Lost' and Probability to 0%. Please confirm"
      );
    case ProbabilityChangeTo100:
      return "Changing the Probability to 100% will change the Status to 'Won'. Please confirm";
    case ProbabilityChangeTo0:
      return "Changing the Probability to 0% will change the Status to 'Lost'. Please confirm";
    case StageChangeToClosedWonMsg:
      return "Changing the Stage to 'Closed Won' will change the Status to 'Won' and Probability to 100%. Please confirm";
    case StageChangeToClosedLostMsg:
      return "Changing the Stage to 'Closed Lost' will change the Status to 'Lost' and Probability to 0%. Please confirm";
    case WonProbability100:
      return "Probability with 'Won' status should be 100 % ";
    case WonStage100:
      return "Stage with 'Won' status should be 100%";
    case LostProb0:
      return "Probability with 'Lost' status should be 0%";
    case LostStage0:
      return "Stage with 'Lost' status should be 0%";
    case OpenProbNot0:
      return "Probability with 'Open' status should not be 0%";
    case OpenProbNot100:
      return "Probability with 'Open' status should not be 100%";
    case OpenStageBtw0And100:
      return "Stage with 'Open' status should not be 0% or 100%";
    case NoAccess:
      return "You have restricted access to the opportunity, need write access to create or update the opportunity.";
    case ReadOnly:
      return "You have restricted access to the opportunity, need write access to create or update the opportunity.";
    case ProbEmpty:
      return "Probability is Mandatory.";
    case LostReasonDuringStatusOpen:
      return "'Closed Lost Reason' can be selected only when status is set to 'Lost'.";
    case OppNameMandatory:
      return "Opportunity Name is Mandatory.";
    case OppAmountMandatory:
      return "Amount is Mandatory.";
    case OppStatusMandatory:
      return "Status is Mandatory.";
    case OppStageMandatory:
      return "Stage is Mandatory.";
    case OppProbabilityMandatory:
      return "Probability is Mandatory.";
    case OppCloseDateMandatory:
      return "Close Date is Mandatory.";
    case OppLostReasonMandatory:
      return "Closed Lost Reason is mandatory when status is set to Lost.";
    case OppLostReasonNotesMandatory:
      return "Adding Notes is mandatory when status is set to Lost.";
    case OppContactMandatory:
      return "Contact is Mandatory.";
    case OppTypeMandatory:
      return "Opportunity Type is Mandatory.";
    case OppBusinessUnitMandatory:
      return "Business Unit is Mandatory.";
    case OppProductMandatory:
      return "Product is Mandatory.";
    case OppPrimaryCampaignSourceMandatory:
      return "Primary Campaign Source is Mandatory.";
    case OppAssignedToMandatory:
      return "Assigned To is Mandatory.";
    case OppNextStepMandatory:
      return "Next Step is Mandatory.";
    case OppCannotMove:
      return 'Opportunities in "Closed Won" or "Closed Lost" cannot be moved back to any of the Open stages.';
    case OPP_FILTER_CANNOT_SELECTED:
      return '"My Opportunities" Filters are disabled when a specific Rep is selected. Please change "Select Rep" filter to "All" to filter by "My Opportunities" filters';
    case AD_NUMBER_USED:
      return "Job # already exists. Either Override the check or click on get default Job # to refresh Job #.";
    case PICKUP_FROM_NOT_SELECTED:
      return "Please select the 'Pickup From' from the dropdown.";
    case DELETE_PRODUCTION_CONTACT_MESSAGE:
      return "Are you sure you want to remove access to the customer portal for this contact?";
    case PRODUCTION_CONTACT_UPDATE_FAIL_MESSAGE:
      return "Failed to update production contact.";
    case JOB_JACKET_STATUS_UPDATE_FAIL_MESSAGE:
      return "Failed to update the status.";
    case PRODUCTION_NOTE_NOT_EDITABLE:
      return "Production Note cannot be editable in Edit Mode";
    case UNAUTHORIZED_TO_EDIT:
      return "You are not authorized to save the note.";
    case COMPPGVALUECONDITION:
      return "CompPG Value should be a whole number between 1 and 10.";
    case ALL_CONTACTS_UNDER_PRODUCTION_CONTACTS:
      return "You have added all the Contacts to Production Contacts.";
    case OPP_PROPOSAL_SELECT:
      return "Please select Product to display the corresponding open proposals";
    case OPP_PROPOSAL_CONVERTED_TO_CONTRACT:
      return "The Proposal integrated to this Opportunity is Converted to Contract.So another Proposal integration is not allowed";
    case OPP_PROPOSAL_PRODUCT_CHANGE:
      return "Changing the Product will remove the existing Proposal link to the Opportunity";
    case Barter_Cannot_Exceed_Net:
      return "The Barter/Trade amount cannot exceed the Net value.";
    case Adjustment_Mandatory:
      return "Please select an Adjustment from the list.";
    case Adjustment_Amount_NonZero:
      return "The Adjustment Amount entered must be non-zero. Please try again.";
    case Note_Mandatory:
      return "A Note is required for this adjustment.";
    case Rate_Cannot_Lessthan_Zero:
      return "The Rate cannot be adjusted to be less than 0.";
    case UpdatedRate_Cannot_Exceed_CurrentRate:
      return "Updated rate cannot be more than the current rate";
    case Agency_Discount_Should_Greaterthan_Zero:
      return "Agency Discount Percetage should be greater than 0.";
    case Valid_AgencyDiscount_Percentege:
      return "Please add a valid agency discount percentage";
    case Startdate_Cannot_Exceed_Enddate:
      return "Start Date should not be greater than End Date";
    case Cpm_Title:
      return "Enter CPM";
    case Cpm_Value_Not_Found:
      return "No CPM value was found on the Ratecard for these options.";
    case Price_Title:
      return "Enter Price";
    case Price_Value_Not_Found_For_Rate_Card:
      return "No price was found on the rate card for these options.";
    case CardRate_Title_Enter_Rate:
      return "MMClientMessage.EnterRate";
    case CardRate_Value_Not_Found_For_Rate_Card:
      return "No value was found on the rate card for these options.";
    case Adjustments_Rate_Reset_Value:
      return "All adjustments (charges and discounts) have been removed from this item.";
    case RateCard_Not_Have_Adsize_Value:
      return `Items cannot be entered for this Ratecard. AdSize is a required option and none have been set up for this Ratecard.
                Please contact your system administrator.`;
    case No_Issue_Selected:
      return "Please select atleast one issue.";
    case Issues_Not_Found:
      return "Either the Rate Card is not set to use the date ranges even though the product does not have an issue set OR issues for this schedule are not defined.";
    case CardRate_Net_Gross_Values_Recheck:
      return "Please double check the entered and calculated values for Card Rate, Net, and/or Gross";
    case Tax_Selection_Info:
      return "Tax not selected, Please select a Tax";
    case Rate_Discount_Name:
      return "Default Discount Applied to Rate";
    case Rate_Charge_Name:
      return "Default Charge Applied to Rate";
    case ClosedIssueCheckFailed:
      return "Date(s) Range entered is in closed Range. Please update and proceed or Contact the System Administrator.";
    case PROPOSALNOTEXISTS:
      return "Invalid Proposal";
    case INVALIDCONTACT:
      return "Please provide Valid Contact";
    case INVOICEALREADYCREATED:
      return "Already Invoiced";
    case INVALIDDATERANGES:
      return "Invalid Dates Selected";
    case GO_TO_ORDER:
      return "Go to Order";
    case DuplicateProposalTemplateLineItem:
      return "This is a duplicate of an existing line item on the proposal, you cannot add the same line item multiple times. Please make any necessary changes to save a new line item.";
    case Adjustments_Removed:
      return {
        title: "Rate Change",
        message: "All adjustments (charges and discounts) have been removed.",
        type: "warning",
        insert: "top ",
        container: "center",
        dismiss: {
          duration: 3000,
        },
      };
    case No_Rate_Found:
      return {
        title: "Rate Change",
        message: "No value was found on the rate card for these options.",
        type: "warning",
        insert: "top ",
        container: "center",
        dismiss: {
          duration: 3000,
        },
      };
    case No_Rate_Adjustment_Removed:
      return {
        title: "Rate Change",
        message:
          "No value was found on the rate card for these options.All adjustments (charges and discounts) have been removed.",
        type: "warning",
        insert: "top ",
        container: "center",
        dismiss: {
          duration: 3000,
        },
      };
    case Details:
      return "Details";
    case Pricing:
      return "Pricing";
    case Schedule:
      return "Schedule";
    case StartDate:
      return "Start Date";
    case EndDate:
      return "End Date";
    case Names:
      return "Names";
    case SalesRep:
      return "Sales Reps";
    case BillingContact:
      return "Billing Contact";
    case AdditionalProducts:
      return "Additional Products";
    case WarningsandAlerts:
      return "Warnings and Alerts";
    case WarningsAndAlertsMessage:
      return "The Payment Plan actual total does not match the installments total.please update the payment plan.";
    case Percentage:
      return "Percentage";
    case Sales_Rep_Error:
      return "Commissioned rep percentages must add up to 100% or Sales rep is not selected.";
    case Order_Deleted:
      return "Orders on this proposal have been deleted.In order to convert the proposal to contracts ,all the orders that were converted should be deleted.";
    case Start_Date_Not_Entered:
      return "Please enter a start/end date for this item";
    case Select_Adjustment:
      return "Select Adjustment";
    case Adjust_Rate:
      return "ADJUST RATE";
    case Adjustment_Amount:
      return "Adjustment Amount";
    case New_Rate:
      return "New Rate";
    case Notes:
      return "Notes";
    case Multiple_Products_Text:
      return "*Selecting products will copy the existing item to them. If any other issues are selected, they will be copied to these products also.";
    case Back_Button:
      return "Back";
    case Next_Button:
      return "Next";
    case Save_Button:
      return "Save";
    case Default_Billing_Contact_Text:
      return "* The default billing contact is currently set to:";
    case Remove_Order_From_Payment_Plan:
      return "Remove Order From Payment Plan";
    case Go_To_Payment_Plan:
      return "Go to Payment Plan";
    case PAYMENT_PLAN_NUMBER:
      return "PAYMENT PLAN NUMBER";
    case BILL_TOTAL:
      return "BILL TOTAL";
    case ACTUAL_TOTAL:
      return "ACTUAL TOTAL";
    case PAYMENT_TOTAL:
      return "PAYMENT TOTAL";
    case Date:
      return "Date";
    case Amount:
      return "Amount";
    case Net:
      return "Net";
    case Gross:
      return "Gross";
    case DURATION_NEEDED:
      return "Please enter the no. of Billing Cycles to continue.";
    case PLEASE_SELECT_COUPONS:
      return "Please select at least one coupon to continue.";
    case PLEASE_SELECT_ADDONS:
      return "Please select at least one addon to continue.";
    case PLEASE_SELECT_PLANS:
      return "Please select Plans";
    case PLEASE_SELECT_PRODUCT:
      return "Please select a product";
    case SPECIFY_PRICE_FOR_INFINITE_RANGE:
      return "Please enter a price/rate for the final range in the pricing model.";
    case PERCENTAGE_OR_AMOUNT_NEEDED:
      return "Please enter a discount percentage or amount to continue.";
    case VALID_TILL_LESS_THAN_CURRENT:
      return "Please enter a future date for this coupons expiration.";
    case COUPON_NAME_MANDATORY:
      return "Please enter a coupon name to continue.";
    case COUPON_CODE_MANDATORY:
      return "Please enter a coupon code to continue.";
    case COUPON_CODE_ALREADY_EXIST:
      return "There is already a Coupon with that Name or Code. Please use different Name or Code.";
    case COUPON_DELETE_ALERT:
      return "This Coupon will be permanently deleted. You will not be able to link this coupon to any subscription.";
    case REDEMPTIONS_PER_CUSTOMER_CANNOT_EXCEED_MAXREDEMPTIONS:
      return "Redemptions per customer must be less than or equal to the maximum redemptions.";
    case MAXREDEMPTIONS_CANNOT_LESS_THAN_REDEMPTIONS:
      return "Max Redemptions cannot be less than claimed Redemptions";
    case DeleteOrdeConfirmationMessage:
      return "Are you sure, you want to delete the order?";
    case SelectInvoiceWarning:
      return "Please select at least one invoice for payment.";
    case SelectSameCustomerWarning:
      return "Please select invoices of single customer.";
    case ChangeEmailWarning:
      return "Updating the email address will remove access to the Customer Account. The new email has to be re-verified. Do you wish to continue?";
    case ValidEmailAlert:
      return "Please enter a valid email.";
    case NoOrdersToDisplayMessage:
      return "There are no Orders";
    case NoTasksToDisplayMessage:
      return "There are no Tasks";
    case NoOrderHistoryToDisplayMessage:
      return "There are no Orders to show for the selected date range.";
    case NoInvoicesToDisplayMessage:
      return "There are no open Invoices";
    case NoBillsToDisplayMessage:
      return "There are no open Bills";
    case NoBillingHistoryToDisplayMessage:
      return "There are no Payments to show for the selected date range.";
    case NoSubscriptionsToDisplayMessage:
      return "There are no Subscriptions";
    case CanNotDelteInvoicedOrderMessage:
      return "Sorry, This Order is already Invoiced. So cannot be deleted.";
    case PasswordExpired:
      return "Your password has expired.";
    case PasswordNotStrong:
      return "Your password is not strong.";
    case CpVerificationNotCompleted:
      return "You have not yet completed the verification process.";
    case NoCustomersFoundForCP:
      return "Error! No Customers found,Please enable the customer.";
    case PartialPaymentMessage:
      return "Reduce amount for partial payment.";
    case FromDateCanNotExceedToDate:
      return "From Date cannot be greater than To Date";
    case SELECT_ORDERS:
      return "No Order Selected. Please select Orders to move";
    case CANNOT_SELECT_ORDER:
      return "This Order has been invoiced and cannot be deleted or moved.";
    case CANNOT_SELECT_ADCELLERANT_ORDER:
      return "This order was created in AdCellerant, it cannot be deleted/moved.";
    case EMAIL_LIMIT:
      return "More than 3 emails are not allowed";
    case DELETE_AGENCY_CONFIRMATION:
      return `Are you sure you wish to remove this agency from this Customer? <br />
                    <br />
                    If there are any Contacts that have this agency contact as the billing contact, it will not be removed. Also,
                    if there are no other agencies the 'Bill To' field will be set back to 'Customer'.`;
    case REMOVE_BILLINGCONTACT_CONFIRMATION:
      return `Are you sure do you want to remove Billing Contact?`;
    case ADVERTISER_SELECT:
      return "Please Select Advertiser.";
    case PRIMARY_CONTACT_CONVERSION_SUCCESS:
      return "Successfully converted to primary contact.<br/><br/> <b>*Note</b> : Please make sure to close or refresh any open tabs for the company and its contacts. Viewing and editing an old contact page that has been switched will not work correctly.";
    case SELECT_CUSTOMER_LINK:
      return "Please select a Customer to Link";
    case ADVERTISER_UNMAP_CONFIRMATION:
      return "Are you sure, you want to unmap this Advertiser?";
    case ADVERTISER_MAP_CONFIRMATION:
      return "Are you sure you want to map this advertiser to this customer? This cannot be undone if there are any orders from AdCellerant added.";
    case GAM_ADVERTISER_MAP_CONFIRMATION:
      return "Are you sure you want to map this advertiser to this customer?";
    case CANNOT_SELECT_INVOICED:
      return "Cannot select invoiced orders.";
    case CANNOT_SELECT_ADCELLERANT:
      return "Cannot select AdCellerant orders.";
    case RESET_ALERT:
      return "Are you sure, you want to reset?";
    case CANNOT_DELETE_PLAN:
      return "Cannot delete Plan , Subscription dependency exits.";
    case BILLING_CYCLES_SHOULD_BE_GREATER_THAN_ZERO:
      return "No. of Billing Cycles should be greater than 0 for Gift Subscription.";
    case PLAN_DELETED:
      return "Successfully deleted the plan";
    case END_UNIT_VALIDATION:
      return "The number of units in the pricing model for this range must be greater than the previous range";
    case PLAN_OR_ADDON_REQUIRED:
      return "Please select a plan or addon for this coupon.";
    case ADDON_NAME_MANDATORY:
      return "Please enter an addon name to continue.";
    case ADDON_CODE_MANDATORY:
      return "Please enter the addon code to continue.";
    case PLAN_ADDON_REQUIRED:
      return "Please select Addon";
    case CONFLICT_MESSAGE:
      return "Conflict with Name / Code";
    case ADDON_CONFLICT:
      return "Addon with that Name or Code already exists.";
    case CANNOT_DELETE_INVOICED_ORDERS:
      return "Cannot delete the following invoiced contract(s):";
    case CANNOT_DELETE_CLOSED_ISSUE_ORDERS:
      return "Cannot delete the following closed issue contract(s):";
    case MAX_COUPONS:
      return "Max coupons added";
    case PERCENTAGE_COUPONS_LIMIT:
      return "Max Percentage Coupons Added.";
    case AMOUNT_COUPONS_LIMIT:
      return "Max Amount Coupons Addes.";
    case SELECT_START_DATE:
      return "Please select the start date for this subscription.";
    case SELECT_GIFTER_EMAIL:
      return "Please enter the gifter email id.";
    case PREVIOUS_DATE_SELECTED:
      return `Warning: you are creating a subscription that starts in the past. Each past billing cycle will be invoiced. If automatic payments are enabled each of the invoices will also be charged to the saved payment method.
            Note: if the subscription also ends in the past, the renewal and end of subscription workflows will not be executed.
            Are you sure you want to continue?`;
    case GIFTER_CONTACT_NOT_SELECTED:
      return "Please select a gifter contact.";
    case CONTACT_NOT_SELECTED:
      return "Invalid Contact ID";
    case TAX_NAME_MANDATORY:
      return "Please enter a tax name to continue.";
    case TAX_GROUP_NAME_MANDATORY:
      return "Please enter a tax group name to continue.";
    case TAX_GROUP_DUPLICATE:
      return "Apply automatically group is already available for this location.";
    case TAX_TYPE_MANDATORY:
      return "Please select the Applicable on for all selected Tax Rates.";
    case TAX_DELETE_ALERT:
      return "This tax will be permanently deleted.";
    case TAXES_DELETE_CONFIRM:
      return "This tax has been mapped to few Tax Groups, On deleting this tax will delete the tax mapping in the linked Tax Groups: ";
    case TAXES_QB_MAPPING:
      return "Mapping MM Taxes to QB Taxes";
    case TAXGROUPS_QB_MAPPING:
      return "Mapping MM TaxGroups to QB Combined Taxes";
    case TAX_AMOUNT_PERCENTAGE_EMPTY_CHECK:
      return "Please enter Percentage to continue";
    case TAX_CONFLICT_NAME:
      return "The name already exists.";
    case ACTIVE_TAX_IN_TAXGROUPS:
      return "This tax has been mapped to few Tax Groups, On making this tax InActive will affect the TaxGroups using this Tax. Do you wish to continue?"
    case CANCELREASON_NAME_MANDATORY:
      return "Please enter a cancel reason name to continue.";
    case CANCELREASON_DELETE_ALERT:
      return "Are you sure you would like to delete this Subscription Cancel Reason";
    case CANCELREASON_CONFLICT_NAME:
      return "Subscription Cancel Reason exists already.";
    case NAME_REQUIRED:
      return "Please enter the Name.";
    case DATE_REQUIRED:
      return "Please enter the Date.";
    case SEND_EMAIL_CONFIRMATION:
      return "Send email to selected contacts?";
    case CANCEL_CAMPAIGN_CONFIRMATION:
      return "This option will cancel the entire campaign. Are you sure you want to proceed with the cancellation?";
    case PLEASE_PROVIDE_FROM_EMAIL:
      return "Valid 'From Email' is required when 'From Name' is provided.";
    case FROM_EMAIL_REQUIRED:
      return "Sender's Email is required";
    case DEFAULT_FROM_EMAIL_REQUIRED:
      return "Default Sender's Email is required";
    case FROM_NAME_REQUIRED:
      return "Sender's Name is required";
    case DEFAULT_FROM_NAME_REQUIRED:
      return "Default Sender's Name is required";
    case CAMPAIGN_REQUIRED:
      return "Campaign is required";
    case CAMPAIGN_TYPE_REQUIRED:
      return "Campaign Type is required";
    case PLEASE_PROVIDE_SCHEDULE_TIME:
      return "Please provide a schedule time when choosing a schedule date";
    case EMAIL_BODY_MANDATORY:
      return "Body Text is required";
    case GMAIL_SEND_DISABLED:
      return "Sending through Gmail is limited to 10 contacts at a time to protect your sender reputation and comply with anti-spam restrictions.";
    case EMAIL_SUBJECT_MANDATORY:
      return "Subject is required";
    case CAMPAIGN_NAME_MANDATORY:
      return "Campaign Name is required";
    case SEND_CRM_EMAIL_FAILED:
      return "Failed to send an email.";
    case CANCEL_CRM_EMAIL_FAILED:
      return "Failed to Cancel the campaign.";
    case CUSTOMER_NO_EMAILS:
      return "Some contacts selected do not have an email address associated with their record. Please update email address on the record, or remove contact from list. ";
    case SEND_EMAIL:
      return "Send Email";
    case EMAIL_SENT:
      return "Email sent successfully!";
    case TAXES_DESCRIPTION:
      return "Subscription plans/Order can be set up to apply taxes to the total purchase. Add, update, or remove tax rates & their labels here. The tax rates will show up in a drop down and can be selected so subscription plans to those orders will have a tax calculated and added to the order";
    case TAX_DELETE_CONFLICT:
      return "Plans OR Subscriptions have been created using this Tax.";
    case TAX_GROUP_DELETE_CONFLICT:
      return "Plans OR Subscriptions have been created using this Tax.";
    case CANNOT_EDIT_TAXES:
      return "Cannot edit this Tax, Proposal/Order/Invoice dependency exists.";
    case CANNOT_DELETE_TAXES:
      return "Cannot delete this Tax, Proposal/Order/Invoice dependency exists.";
    case CANNOT_EDIT_TAXGROUPS:
      return "Cannot edit this Tax Group, Proposal/Order/Invoice dependency exists.";
    case CANNOT_DELETE_TAXGROUPS:
      return "Cannot delete this Tax Group, Proposal/Order/Invoice dependency exists.";
    case DELETE_SUBSCRIPTION:
      return "Are you sure you want to delete this subscription, its billing & A/R history, and remove it from any workflows its on? The subscriber/customer will not be deleted. This action cannot be undone!.";
    case DASHBOARD_ALL_NOTES_ACTIVITIES:
      return "All Notes Activities";
    case DASHBOARD_ACTIVITY_HELP:
      return "Displays a list of 5 upcoming calls and/or meetings that are scheduled within the next 7 days";
    case DASHBOARD_RELOAD:
      return "Reload";
    case DASHBOARD_TODAYS_CALLBACKS:
      return "Today's Callbacks";
    case DASHBOARD_TODAYS_CALLS_MEETINGS:
      return "Today's Meetings and Callbacks";
    case INVOICE_TEMPLATE_MANDATORY:
      return "Invoice template mandatory";
    case ATLEAST_ONEITEM_SELECTED:
      return "Select at least one criteria";
    case DUEDATE_MANDATORY:
      return "Due date mandatory";
    case INVOICEDATE_MANDATORY:
      return "Invoice date mandatory";
    case SALESREP_MANDATORY:
      return "Sales rep mandatory";
    case CASHAMOUNT_MANDATORY:
      return "Cash amount mandatory";
    case DASHBOARD_CALLS_MEETINGS_TITLE:
      return "CALLS & MEETINGS";
    case DASHBOARD_QUICK_LINKS:
      return "QUICK LINKS";
    case DASHBOARD_OPPORTUNITIES:
      return "Opportunities";
    case DASHBOARD_YEAR_TO_DATE_BOOKINGS:
      return "YEAR TO DATE BOOKINGS";
    case DASHBOARD_YEAR_TO_DATE_ACTIVITIES:
      return "YEAR TO DATE ACTIVITIES";
    case DASHBOARD_OPPORTUNITY_TITLE:
      return "Go to All My Open Opportunities";
    case DASHBOARD_OPPORTUNITY_HELP:
      return "Listing next 5 upcoming opportunities";
    case DASHBOARD_YTDACTIVITY_HELP:
      return "This chart gives the count of Calls and Meetings completed along with Emails sent for the current year.";
    case DASHBOARD_MORE:
      return "More";
    case DASHBOARD_YTDACTIVITY_TITLE:
      return "YEAR TO DATE ACTIVITIES";
    case DASHBOARD_ALLYTDACTIVITIES:
      return "New-Activity";

    case DASHBOARD_YTDBOOKING_HELP:
      return "This chart gives the count of Goals, Sales and Proposals for the current year.";
    case DASHBOARD_ALLYTDBOOKINGS:
      return "Sales Runsheet";
    case DASHBOARD_CALENDAR:
      return "Calendar";
    case DASHBOARD_NO_OPPORTUNITY:
      return "No Open Opportunities... Yet";
    case DASHBOARD_NO_CALLS_MEETINGS:
      return "No calls or meetings are scheduled.";
    case CANNOT_VOID_LOCKED_INVOICE:
      return "This Invoice has been locked.<br/> It cannot be voided unless a Billing Administrator unlocks it.";

    case DELETE_TEMPLATE_INFO:
      return "Are You Sure You Want to Delete this Template?";
    case TAX_NOT_SELECTED:
      return "Please select Tax from the dropdown list!.";
    case Msg_ValidationOnContractsForSameContact:
      return "Please select orders which are created for same company.";
    case Msg_ValidationOnContractsForSameIssue:
      return "Please select order(s) having same publication schedule/issue set.";
    case IssueChangeIsOnlyForIssueBasedOrders:
      return "Change issue option is only for the issue based order(s)/Invoiced order(s).";
    case MsgCnf_UpdateOrders:
      return "Are you sure you want to update all of the selected orders. This action cannot be undone.";
    case Msg_ProceedInvCheckOrders:
      return "Proceed, even if inventory limit exceeds for the select orders.";
    case Msg_PleaseSelectIssueToChange:
      return "Please select an issue to change.";
    case MSG_ARREPORT_HAVEOPENPOSTDATEDIINVOICES:
      return "Warning! You have open postdated invoices that do not show on this aging report.  Please adjust the As of Date accordingly to see these items.";
    case MSG_ARREPORT_HAVEOPENPOSTDATEPAYMENTS:
      return "Warning! You have open postdated invoices that do not show on this aging report.  Please adjust the As of Date accordingly to see these items.";
    case MSG_PAYMENT_CREDIT_DEBIT_WITHOUT_QB:
      return "You are about to credit invoices in payments and debit it to the customer account. Any invoices that were paid will return to an unpaid status. Please verify that all the invoice payments are selected for this action if you want to transfer the entire original payment amount. <br/><br/> This action cannot be voided or undone. Are you sure you want to continue?";
    case MSG_PAYMENT_CREDIT_DEBIT_WITH_QB:
      return "You are about to credit invoices in payments and debit it to the customer account. Any invoices that were paid will return to an unpaid status. Please verify that all the invoice payments are selected for this action if you want to transfer the entire original payment amount. <br/><br/> Selected records contains the payments which are sent to QuickBooks. So, once payment credit/debit are made same need to done in Quickbooks manually. <br/><br/> This action cannot be voided or undone. Are you sure you want to continue?";
    case MSG_ARREPORT_HAVECLOSEDPREPAYSINPOSTDATEPAYMENTS:
      return "Warning! You have prepays showing as available that have already been applied to invoices due to postdated payments.  Please adjust the As of Date accordingly to see these items.";
    case ORDER_SEARCH_GANTT_VIEW_NO_DATA_MESSAGE:
      return "There are no orders between the selected date filter.";
    case DELETE_STAGE_HAS_DEPENDENCY:
      return "There are orders associated with this stage, it cannot be deleted.";
    case DELETE_STAGE_REQUIRES_STAGEID:
      return "Stage Id is required.";
    case UPDATE_STAGE_IN_ORDER:
      return "Are you sure you want to update the current stage to";
    case REMOVE_STAGE_IN_ORDER:
      return "Are you sure you want to remove the current stage";
    case ORDER_STAGE_NAME_REQUIRED:
      return "Stage name is required";
    case ORDER_STAGE_PRODUCT_TYPE_REQUIRED:
      return "Product type is required";
    case ORDER_STAGE_NAME_AND_PRODUCT_TYPE_REQUIRED:
      return "Stage name and Product type are required";
    case DUPLICATE_ORDER_STAGE_NAME:
      return "Stage with the provided name already exists.";
    case PRODUCTTYPE_CANNOT_UPDATED:
      return "There are orders associated with this stage, product type cannot be updated.";
    case INVALID_REQUEST:
      return "Invalid Request";
    case PRODUCTION_FIELD_VALUE_EMPTY:
      return "Field value can not be empty.";
    case BATCH_UPDATE_OPTION_EMPTY:
      return "Please select an option to update.";
    case NoMapAddontoCoupon:
      return "This Coupon is not mapped to Addon.";
    case NoMapPlantoCoupon:
      return "This Coupon is not mapped to Plan.";
    case ORDER_NUMBER_CANNOT_BE_EMPTY:
      return "Job # can not be empty.";
    case DUPLICATE_PROSPECTING_STAGE_NAME:
      return "Prospecting Stage with the provided name already exists.";
    case DELETE_PROSPECTING_STAGE_HAS_DEPENDENCY:
      return "There are Customers mappings for this stage, it cannot be deleted.";
    case DELETE_LEAD_TYPE_REQUIRES_LEADTYPEID:
      return "Lead Type Id is required.";
    case DELETE_LEAD_TYPE_HAS_DEPENDENCY:
      return "There are dependency mappings for this lead type, it cannot be deleted.";
    case DELETE_LEAD_STATUS_HAS_DEPENDENCY:
      return "There are dependency mappings for this lead status, it cannot be deleted.";
    case DELETE_LEAD_SOURCE_HAS_DEPENDENCY:
      return "There are dependency mappings for this lead source, it cannot be deleted.";
    case DUPLICATE_LEAD_TYPE_VALUE:
      return "Lead Type with the provided value already exists.";
    case DUPLICATE_LEAD_STATUS_VALUE:
      return "Lead Status with the provided value already exists.";
    case DUPLICATE_LEAD_SOURCE_VALUE:
      return "Lead Source with the provided value already exists.";
    case SUBJECT:
      return "Subject";
    case DESCRIPTION:
      return "Description";
    case FILENAME:
      return "File Name";
    case EMAIL:
      return "Email";
    case NAME:
      return "Name";
    case SEND:
      return "Send";
    case HISTORY:
      return "History";
    case CLOSE:
      return "Close";
    case INVALID_DATA:
      return "Invalid Data";
    case INAVLID_POPOSAL_ID:
      return "Inavlid Poposal ID";
    case INAVLID_SALES_CONTACT_ID:
      return "Inavlid Sales Contact ID";
    case SUCCESS:
      return "Success";
    case PROPOSAL_LINE_ITEMS_MOVE_SUCCESS:
      return "The selected proposal line items moved successfully.";
    case PROPOSAL_LINE_ITEMS_COPY_SUCCESS:
      return "Proposal copied successfully.";
    case ESIGN:
      return "E-sign";
    case ESIGN_CUSTOMER:
      return "Send for Customer Signature";
    case ESIGN_CUSTOMER_AND_REP:
      return "Send for Customer &amp; Rep Signatures";
    case ESIGN_VIEW_DOCUMENT_HISTORY:
      return "View Documents History";
    case ESIGN_FOR_CUSTOMER:
      return "E-Sign for Customer";
    case ESIGN_FOR_CUSTOMER_AND_REP:
      return "E-Sign for Customer and Rep";
    case ESIGN_CUSTOMER_SIGNER:
      return "Customer (Signer)";
    case ESIGN_REP:
      return "Rep (CC)";
    case ESIGNATURE_CHARGES_TEXT:
      return "Note: You will be charged for each document that you send for eSignature.";
    case EMPTY_SUBJECT:
      return "Please enter a subject.";
    case EMPTY_DESCIPTION:
      return "Please enter a description.";
    case EMPTY_FILE_NAME:
      return "Please enter a File Name.";
    case FILE_NAME_ALREADY_EXISTS:
      return "File name already exists, please select a different file.";
    case EMPTY_CC_EMAIL:
      return "Please enter a CC recipient Email address.";
    case EMPTY_CC_NAME:
      return "Please enter a CC recipient name.";
    case EMPTY_SIGNER_EMAIL:
      return "Please enter a Signer Email address.";
    case EMPTY_SIGNER_NAME:
      return "Please enter a Signer name.";
    case INVALID_SIGNER_EMAIL:
      return "The entered Signer Email is invalid format.";
    case INVALID_CC_EMAIL:
      return "The entered CC Email is invalid format.";
    case ESIGN_CUSTOMER_AND_REP_SAME_EMAIL:
      return "The Customer and Rep email addresses cannot be same.";
    case SENTFORESIGN:
      return "The document has been sent for eSignature using RightSignature.";
    case ESIGNATURE_STATUS:
      return "eSignature Status";
    case ESIGN_NO_DOC_HISTORY:
      return "The document has never been sent for eSignature.";
    case COMPLETE:
      return "Complete";
    case PENDING:
      return "Pending";
    case CANCELLED:
      return "CANCELLED";
    case EXPIRED:
      return "EXPIRED";
    case SIGNED_BY_ONE_PARTY:
      return "SIGNED-BY-ONE-PARTY";
    case ESIGN_CON_MSG_TRASH_DOC:
      return "Are you sure you want to cancel this document?";
    case SOURCE_NAME_MANDATORY:
      return "Please enter a Source name to continue.";
    case SOURCE_DELETE_ALERT:
      return "Are you sure you would like to delete this Subscription Source";
    case SOURCE_CONFLICT_NAME:
      return "Subscription Source exists already.";
    case DELETE_SOURCE_REQUIRES_SOURCEID:
      return "Source Id is required";
    case INVALID_FILENAME:
      return "File name can contain only the following characters (_!.'()-)){}&[]";
    case AUTH_ID_CONFLICT:
      return "This authorization ID has already been used. Please enter a new authorization ID.";
    case INACTIVATE_CONTACT_MESSAGE:
      return "Are you sure you want to inactivate this contact record? You will not be able to edit the data or add any new items (sub-contacts, proposals, orders, etc.) to an inactive contact.";
    case REACTIVATE_CONTACT_MESSAGE:
      return "Are you sure you want to reactivate this contact?";
    case INACTIVATE_INFO_MESSAGE:
      return "You can inactivate a contact which will remove them from the contact/customer search results, hide them from the lists of sub-contacts, and new items (proposals, orders, etc.) cannot be added for the contact.";
    case INACTIVATE_INFO_MESSAGE_BATCHUPDATE:
      return "Are you sure you want to inactivate all of the selected contact records? You will not be able to edit the data or add any new items (sub-contacts, proposals, orders, etc.) to an inactive contact. Some of them may be being used as production or billing contacts and setting them to inactive could affect new orders; you can see these types of contacts only if you inactivate them individually.";
    case INACTIVE_CONTACT_NOTE:
      return "Inactive Contacts - Please check the advanced search to find inactive contacts, you may want to re-activate a contact rather than adding a duplicate record to see history, notes, etc.";
    case UPDATE_PROPOSAL_TEMPLATE:
      return "Are you sure, you want to change the Proposal Template? This will override the Upper text, Lower text and Line Items.";
    case UPDATE_CHANGEORDER_TEMPLATE:
      return "Are you sure, you want to change the Change Order Template? This will override the Upper text, Lower text and Line Items.";
    case PROPOSAL_TEMPLATE_UPDATED_SUCCESSFULLY:
      return "The template has been updated successfully.";
    case Adjustment_AddORRate:
      return "We cannot add multiple adjustments for a line item as the Google Ad Manager supports single adjustment.";
    case CHANGEORDER_EXISTS:
      return "One or more selected orders already have a change order associated with them.";
    case CHANGEORDER_CLOSED_ISSUE_EXISTS:
      return "One or more selected orders are associated with closed issues. Please check remove invoiced orders to proceed.";
    case CHANGEORDER_INVOICE_EXISTS:
      return "One or more selected orders are invoiced. Please check and remove invoiced orders to proceed.";
    case CHANGEORDER_DIFFERENT_CUSTOMERS:
      return "Selected orders belong to different customers.";
    case CHANGEORDER_DIFFERENT_PRODUCTTYPES:
      return "Selected orders are of different Product Types.";
    case CHANGEORDER_CREATED:
      return "Change Order has been created successfully.";
    case CHANGEORDER_ADDED:
      return "Change requests added to the selected change order successfully.";
    case CHANGEORDER_NOT_PENDING:
      return "Selected change order is not in pending state.";
    case CHANGEORDER_NOT_EXISTS:
      return "Selected change order does not exist; it may have been deleted.";
    case CHANGEORDER_ORDER_DELETED:
      return "One of the original order has been deleted, Please check and remove corresponding order from change order list to proceed.";
    case CHANGEORDER_DIFFERENT_RATECARD_TYPES:
      return "Selected orders related to different rate card types.";
    case DEPENDENCY_AD_MASTER:
      return "This record is dependent with proposals or orders.";
    case CHANGEORDER_USER_NOT_AUTHORIZED_TO_CREATE_CHANGEORDER:
      return "User not authorized to create change order.";
    case CHANGEORDER_USER_NOT_AUTHORIZED_TO_APPLY_CHANGEORDER:
      return "User not authorized to apply change order.";
    case FORMULA_RAN_WITHOUT_ERROR_INFO:
      return "The formula ran without any errors.You will need to verify values after this field is set up.";
    case FORMULA_CAUSED_ERROR_INFO:
      return "The formula caused an error. Please correct it and try again.";
    default:
      return messageType;
  }
};

export const page = {
  OPPORTUNITYREPORT: "OpportunityReport",
  CONTACTSEARCH: "ContactSearch",
  CONTACTEDIT: "ContactEdit",
};

export const planHelpText = {
  PLAN_DEFINATION:
    "Plans define the cost, frequency, terms, and length of subscription offered for purchase. Addons & coupons can be added to plans to offer packages and discounts to customers.",
  BILLING_INTERVAL_DESCRIPTION:
    "Set up the frequency and duration of each cycle for subscriptions to this plan. As each billing cycle starts the subscription will create the necessary orders, invoices, payments (if auto-pay is enabled), and notifications for the subscription. At the final billing cycle any end actions (renewals & notifications) are handled.",

  ADDONS_DESCRIPTION:
    "Addons are extra products offered for purchase along with this subscription plan. Addons share the same billing cycles as the plan however they have their own pricing models. Addons allow you to create packages, one-time purchases, and customize your product catalog.",

  GIFTS_DESCRIPTION:
    "Enable gift subscriptions of this plan to be purchased on behalf of subscribers. When enabled the checkout pages will ask for a different billing contact that will be charged for each billing cycle of the subscription. The gifter will get invoices and receipts, but all other communication and portal access for the subscription will go to the subscriber.",
  SHIPPING_ADDRESS_DESCRIPTION:
    " If the shipping address setting is enabled subscribers can override their address for certain periods of the year. When subscriptions are requested during the address override period, this shipping address is shown instead of the primary. This would mostly be used for subscriptions to physical products to be delivered.",
  PLAN_WARNING:
    "There are existing subscriptions to this plan; some of the fields cannot be changed. The selected product, pricing model, max quantity, end action, and billing interval settings are locked. Please consider copying this one to a new plan and making changes there.",
  NAME: "Each plan must have a unique name, duplicate plans are not allowed.",
  DISPLAY_NAME:
    'This "Display Name" will be shown on the checkout pages and customer portal for subscriptions to this plan.',
  CODE: "The plan code is used to help identify the plan on reports, invoices, and other screens. You can override the default if needed.",
  DESCRIPTION:
    "Describe the plan so customers and users have more information about it.",
  FEATURES:
    'The feature list is displayed on checkout pages that offer this plan. You can add any feature text and show an optional "is available" icon or checkbox. Use the feature list to create plan comparisons or highlights on the checkout pages.',
  FEATURES_MODEL: "Is Available",
  PRODUCT:
    "Select the subscription type product offered for purchase on this plan. Each billing cycle of a subscription will create an order in the application wihch is invoiced, paid, and stored in the database.",
  ACCOUNTING_CODE:
    "Add an optional accounting code that can be used to categorize orders, invoices, & payments for subscriptions to this plan.",
  MAX_QUANTITY:
    "You can limit the number of plans that can be purchased in a single transaction. Leave it blank for no limit.",
  SETUP_FEE:
    "You can add a mandatory setup fee to the first billing cycle of all subscriptions to this plan. It is charged only once, it doesn't recur on any other billing cycles.",
  APPLY_TAXES:
    "Coming Soon: Apply a calculated tax to the price per billing cycle. The tax tables can be set up to calculate a tax based on certain subscription details like the subscriber's address.",
  INTERVAL:
    "Set the duration of each billing cycle. For instance, for a monthly subscription enter 1 and choose months. Each subsequent billing cycle starts after this interval.",
  NO_OF_BILLLING_CYCLES:
    "The number of billing cycles defines the entire subscription term. At the start of each of these billing cycles the plan rate and any addons or fees are charged to the subscriber's credit card and the orders & invoices are created. Subscriptions from the checkout pages are always set to this number but it can be changed through the web interface. ",
  AUTOMATIC_PAYMENTS: `Set this to automatically charge the payment at the start of each billing cycle. The subscription workflow will try to get the payment and create invoices, orders, and even subscription renewals. Non-payment (dunning) decisions and notifications can be customized using workflows.<br/>If automatic payments are not enabled the invoices are created and all billing and payments are done through the web interface A/R applications or the customer portal.`,
  INVOICE_TEMPLATE:
    "Choose the template for the invoices that will be created in the A/R system. When required the application can send a download link to the invoice so customers can download a copy.",
  TRIAL_PERIOD:
    'Subscriptions to this plan will begin with a free trial period. The duration of the trial is set here. When this plan is purchased it\'s status is set to "In Trial" and a $0.00 order and invoice are created. Immediately after the trial ends (the start date plus duration) the first billing cycle will start, continuing the subscription with an "Active" status.',
  FREE_TRIAL:
    "Set how long after the start date until the first billing cycle start.",
  ADDON:
    "Choose any addons that are available to add to subscriptions to this plan. You can also create a new addon to be selected if the right one isn't defined yet.",
  MAX_QUANTITY_ADDON:
    "Limit how many of this addon can be purchased along with the plan in a single transaction.",
  EXPIRY:
    "Set the amount of time before the gift must be claimed. Unclaimed gift suscriptions are cancelled and not available for any action after the expiry date.",
  AUTO_CLAIM:
    "Set this to immediately claim the gift for a subscriber without their explicit approval. The subscription will be created and the first biling cycle will be charged to the gifter as normal.",
  PRICING_MODEL:
    "Choose and setup a pricing model. The pricing model will determine the price per billing cycle of any subscriptions purchased for this plan. View examples and more details about the different pricing models by",
  RATE_FLAT_FEE:
    "This is the base rate of the plan (before addons and setup fees), the flat fee pricing model ignores quantity.",
  RATE_PER_UNIT:
    "This is the rate calculated by the price times quantity, the per unit pricing model calculates the rate automatically.",
  PAY_IMMEDIATLY:
    "Select this option if payments for subscriptions to this plan should be charged when the subscription is purchased, instead of on the start of the first billing cycle.",
  BILLING_CYCLE_START:
    "Use this option to force all billing cycles to start on a specific day. If the subscription is purchased before that billing cycle start, it�s start date/first billing cycle will start on the next start date available.",
  BILLING_CYCLE_CLOSE:
    "Use this option along with the billing cycle start to reserve some time before a billing cycle that subscriptions cannot be entered. This can allow for a fulfillment period where no new subscriptions should be added.",
  STARTS_ON: "When should the billing cycles for this plan start?",
  SALES_REPS:
    "Choose the sales rep this is assigned by default to new subscribers of this plan.",
  FEATURES_HELP_TEXT:
    "Plan features show up on the checkout pages when purchasing this plan. They can help customers decide between plans or be used to showcase parts of this plan. You can enter any number of features and show an available, unavailable, or no icon next to the feature.",
  COUPONS_PLAN_HELP_TEXT: `You can offer coupons and discounts to subscribers of this plan.Coupons require a code to be entered when a subscription is created on the checkout forms or here in the web UI.Automatic discounts are applied to every subscription to this plan,they can be used to provide flexible pricing options for plans ,addons and specific billing cycles.`,
  TOTAL_AVAILABLE_HELP_TEXT:
    "This will limit the number of subscriptions of this plan that can be created across all subscribers from the checkout pages and the ChargeBrite application.",
  DISPLAY_PLAN_IMAGE_TEXT:
    "Upload an image that can be displayed on the checkout form for this plan",
  SEND_INVOICE_EMAIL_TEXT:
    "Send an email automatically when the subscription is created and paid with a link to download the invoice for this subscription order.",
  APPLY_AUTOMATIC_TAXES:
    "Enable this option to apply tax groups automatically to the subscriptions based on the subscriber's location. If option is disabled, only one tax / tax group will be allowed to select for the plan and it will be auto applied while creating a subscription.",
  MINIMUM_UNIT: "You can set the minimum unit/quantity for the subscription.",
};
export const planHelpTextForOrder = {
  PLAN_DEFINATION:
    "Plans define the cost, frequency,terms, and length of Orders offered for purchase. Addons & coupons can be added to plans to offer packages and discounts to customers.",
  BILLING_INTERVAL_DESCRIPTION:
    "Set up the frequency and duration of each cycle for Orders to this plan. As each billing cycle starts the order will create the necessary orders, invoices, payments (if auto-pay is enabled), and notifications for the order. At the final billing cycle any end actions (renewals & notifications) are handled.",

  ADDONS_DESCRIPTION:
    "Addons are extra products offered for purchase along with this Order plan. Addons share the same billing cycles as the plan however they have their own pricing models. Addons allow you to create packages, one-time purchases, and customize your product catalog.",

  PLAN_WARNING:
    "There are existing Orders to this plan; some of the fields cannot be changed. The selected product. Please consider copying this one to a new plan and making changes there.",
  NAME: "Each plan must have a unique name, duplicate plans are not allowed.",
  DISPLAY_NAME:
    'This "Display Name" will be shown on the checkout pages and customer portal for orders to this plan.',
  CODE: "The plan code is used to help identify the plan on reports, invoices, and other screens. You can override the default if needed.",
  DESCRIPTION:
    "Describe the plan so customers and users have more information about it.",
  FEATURES:
    'The feature list is displayed on checkout pages that offer this plan. You can add any feature text and show an optional "is available" icon or checkbox. Use the feature list to create plan comparisons or highlights on the checkout pages.',
  FEATURES_MODEL: "Is Available",
  PRODUCT:
    "Select the order type product offered for purchase on this plan. Each billing cycle of a order will create an order in the application wihch is invoiced, paid, and stored in the database.",
  ACCOUNTING_CODE:
    "Add an optional accounting code that can be used to categorize orders, invoices, & payments for orders to this plan.",

  INVOICE_TEMPLATE:
    "Choose the template for the invoices that will be created in the A/R system. When required the application can send a download link to the invoice so customers can download a copy.",

  ADDON:
    "Choose any addons that are available to add to orders to this plan. You can also create a new addon to be selected if the right one isn't defined yet.",
  MAX_QUANTITY_ADDON:
    "Limit how many of this addon can be purchased along with the plan in a single transaction.",

  PAY_IMMEDIATLY:
    "Select this option if payments for orders to this plan should be charged when the order is purchased",

  SALES_REPS:
    "Choose the sales rep this is assigned by default to new advertisers of this plan.",
  FEATURES_HELP_TEXT:
    "Plan features show up on the checkout pages when purchasing this plan. They can help customers decide between plans or be used to showcase parts of this plan. You can enter any number of features and show an available, unavailable, or no icon next to the feature.",
  COUPONS_PLAN_HELP_TEXT: `You can offer coupons and discounts to advertisers of this plan.Coupons require a code to be entered when a orders is created on the checkout forms or here in the web UI.Automatic discounts are applied to every order to this plan,they can be used to provide flexible pricing options for plans and addons.`,
  TOTAL_AVAILABLE_HELP_TEXT:
    "This will limit the number of subscriptions of this plan that can be created across all advertisers from the checkout pages and the ChargeBrite application.",
  DISPLAY_PLAN_IMAGE_TEXT:
    "Upload an image that can be displayed on the checkout form for this plan",
  SEND_INVOICE_EMAIL_TEXT:
    "Send an email automatically when the order is created and paid with a link to download the invoice for this order.",
  AUTOMATIC_PAYMENTS: `Set this to automatically charge the payment at the start of each billing cycle. The subscription workflow will try to get the payment and create invoices, orders, and even subscription renewals. Non-payment (dunning) decisions and notifications can be customized using workflows.<br/>If automatic payments are not enabled the invoices are created and all billing and payments are done through the web interface A/R applications or the customer portal.`,
  APPLY_AUTOMATIC_TAXES:
    "Enable this option to apply tax groups automatically to the subscriptions based on the subscriber's location. If option is disabled, only one tax / tax group will be allowed to select for the plan and it will be auto applied while creating a subscription.",
};
export const addonHelpText = {
  ADDON_DEFINATION:
    "Addons are additional products added to a subscription. The addons can be recurring or one-time. A recurring addon is charged each billing cycle. Mandatory addons will always be purchased with the plan.",
  ADDON_WARNING:
    "There are existing subscriptions with this addon; some of the fields cannot be changed. The selected product, pricing model, and charge type settings are locked. Please consider copying this addon and making changes there.",
  NAME: "Each addon must have a unique name, duplicate addons are not allowed.",
  CODE: "The addon code is used to help identify the plan on reports, invoices, and other screens. You can override the default if needed.",
  DESCRIPTION:
    "Describe this addon so customers and users have more information about it.",
  PRODUCT:
    "Select the subscription type product offered as this addon; an order for this product will be added to the billing cycles for a purchased subscription.",
  ACCOUNTING_CODE:
    "Add an optional accounting code that can be used to categorize orders, invoices, & payments for subscriptions to this plan.",
  CHARGE_TYPE:
    "Choose if this addon will be added each billing cycle (recurring) or just on the first cycle (one time).",
  PRICING_MODEL:
    "Choose a pricing model that determines the cost of this addon when added to a subscription plan. The price/rate is for each billing cycle not for the entire term",
  DISPLAY_ADD_ON_IMAGE_TEXT:
    "Upload an image that can be displayed on the checkout form for this addon.",
};

export const couponHelpText = {
  COUPON_DEFINATION: "",
  COUPON_WARNING:
    "There are existing subscriptions with this coupon applied; some of the fields cannot be changed. The selected discount type, duration, and applies on settings are locked. Please consider copying this coupon and making changes to the new one.",
  NAME: "Each coupon must have a unique name, duplicate coupons are not allowed.",
  CODE: "This will be the coupon customers will need to enter to apply the discount. You can overwrite the default code if needed.",
  DESCRIPTION:
    "Describe the coupons so customers and users have more information about it.",
  DISCOUNT_TYPE:
    "Choose if the coupon is a flat discount or taken as a percent of the total for a billing cycle.",
  // DURATION_TYPE: "",
  APPLY_ON:
    "Apply the coupon to the invoice amount to discount the entire price of a billing cycle. You can also select to apply the coupon only to each specified item (the plan or addons).",
  MAX_REDEMPTIONS:
    "Set the maximum times this coupon may be used across all customers when purchasing this plan. Once the limit is reached this coupon code will not be validated or applied to any new subscriptions.",
  VALID_TILL:
    "You can set an expiration date for this coupon. After this date the coupon code will not be validated or applied to any new subscriptions.",
  REDEMPTIONS_PER_CUSTOMER:
    "Set the maximum times this coupon may be used by a single customer when purchasing subscriptions to this plan. Once the limit is reached this coupon code will not be validated or applied to any new subscriptions.",
  CouponHeaderText:
    "You can offer subscribers discounts on subscriptions using coupons. A coupon must be attached to a plan for it to be accepted. Coupon discounts are verified against the list of valid coupons and their conditions; if it's valid the amount will be deducted from the rate for the billing cycle.Customers are given a coupon code entry on the checkout pages where they can enter their coupons to be checked against the plan being purchased.",
};

export const endAction = {
  END_ACTION:
    "Choose what happens to subscriptions after their last billing cycle.",
  EXPIRE: "After last billing cycle, do nothing; the subscription is complete.",
  RENEW_ONCE:
    "At the last billing cycle the subscription is copied with its End Action set to Expire. The Subscription will be active for one more term.",
  AUTO_RENEW:
    "At the last billing cycle renew/copy the subscription for another full term and set the End Action to AutoRenew. The Subscription will continuously renew.",
  UNTIL_CANCELLED:
    "A single billing cycle is charged once at the start of the subscription and it never has to be paid for again.",
};

export const invoiceOptions = {
  INVOICE_ACTION:
    "Choose how the subscription will be invoiced and handled in the account receivables module.",
  INVOICE_PAY_NOW:
    "Invoices for the orders for this billing cycle are created at the start of the billing cycle. If automatic payments are enabled the payments are enabled the payment will also be applied.",
  INVOICE_PAY_LATER:
    "Invoices are not created during billing .They will need to be created through the accounts receivables module. If automatic payments are enabled the payments will post to the subscriber's account to be applied through the web interface as well.",
};

export const textToUpdated = {
  text: "Choose the sales rep that is assigned to subscriptions to this plan.",
  contactType:
    "Choose the contact type that is assigned by default to new subscribers of this plan.",
};
export const textToUpdatedForOrder = {
  text: "Choose the sales rep that is assigned to order to this plan.",
  contactType:
    "Choose the contact type that is assigned by default to new advertisers of this plan.",
};

export const taxHelpText = {
  NAME: "Each tax must have a unique name, duplicate tax are not allowed.",
  DESCRIPTION:
    "Describe the tax so customers and users have more information about it.",
  DISCOUNT_TYPE: "Type",
  IS_DEFAULT_TAX: "apply tax automatically based on default tax",
  IS_TAX_ACTIVE: "Is Tax Active",
};
export const taxGroupHelpText = {
  NAME: "Each tax group must have a unique name, duplicate tax groups are not allowed.",
  TAXES: "All taxes applied on Net amount",
  ORDER_TAXES: "Choose Taxes",
  ISBASEDONLOCATION: "Is apply taxes automatically based on Customer Location",
  IS_TAXGROUP_ACTIVE: "Is Tax Group Active",
};
export const cancelReasonHelpText = {
  NAME: "Each cancel reason must have a unique name, duplicate cancel reason are not allowed.",
};
export const subscriptionSourceHelpText = {
  NAME: "Each subscription source must have a unique name, duplicate subscription source are not allowed.",
};

export const workflowCancelOptionHelpText = {
  name: "Workflow Cancel Option",
  description:
    "Choose when the active/non-renew subscription should be cancelled by the workflow due to Payment problems",
};

export const SUB_PRODUCT_TYPE_IDS = {
  PRINT: 1,
  DIGITAL: 3,
};

export const invoiceHelpText = {
  InvoiceTemplate: "Invoice Template",
};

//contains all table columns in application
export const gridColumns = Object.freeze({
  SELECT: "Select",
  CURRENCY: "Currency",
  DELETE: "Delete",
  EDIT: "Edit",
  STATUS: "Status",
  INVOICE_NUMBER: "Invoice #",
  INSTALLMENT: "Installment #",
  INVOICE_DATE: "Invoice Date",
  DUE_DATE: "Due Date",
  PRODUCT: "Product",
  COMPANY_NAME: "Company Name",
  AGENCY_NAME: "Agency Name",
  COMPANY: "Company",
  BILL_TO: "Bill To",
  BILLTO: "BillTo",
  SALES_REP: "Sales Rep",
  INVOICE_TOTAL: "Invoice Total",
  AMOUNT_DUE: "Amount Due",
  BARTER: "Barter",
  BARTER_DUE: "Barter Due",
  LINEITEMBLOCKS: "Line Item Block",
  DESCRIPTION: "Description/Selected Columns",
  TOTAL: "Total",
  DATE: "Date",
  TRANSACTION_TYPE: "Transaction Type",
  INVOICENUMBER: "Invoice Number",
  PAYMENT_NUMBER: "Payment Number",
  PAYMENT_PLAN_NUMBER: "Payment Plan #",
  PAYMENT_METHOD: "Payment Method",
  MEMO: "Memo",
  CASH: "Cash",
  PROCESSING_FEE: "Processing Fee",
  CUSTOMER_ACCOUNT: "Customer Account",
  TRANSACTION_SUMMARY: "Transaction Summary",
  BALANCE_ON: "Balance on",
  TOTAL_INVOICED: "Total Invoiced",
  TOTAL_PAYMENTS: "Total Payments",
  INVOICE_PAYMENTS: "Invoice Payments",
  PAYMENTS_TO_INVOICES: "Payments to Invoices",
  PAYMENTS_TO_INVOICES_FROM_CUSTOMER_ACCOUNT:
    "Payments to Invoices from Customer Account",
  CREDITS_TO_INVOICES: "Credits to Invoices",
  PROCESSING_FEE_TOTALS: "Total Processing Fee",
  TOTAL_INVOICE_PAYMENTS: "Total Invoice Payments",
  CUSTOMER_ACCOUNT_PAYMENTS: "Customer Account Payments",
  PREPAYS_AND_CREDITS_TO_CUSTOMER_ACCOUNT:
    "Prepays and Credits to Customer Account",
  PAYMENTS_FROM_CUSTOMER_ACCOUNTS_TO_INVOICES:
    "Payments from Customer Accounts to Invoices",
  TOTALS_PREPAY_CREDITS: "Total Prepays & Credits",
  TRANSACTION_REPORT_TOTALS: "Transaction Report Totals",
  TOTAL_CASH: "Total Cash",
  TOTAL_CASH_AMOUNT: "Total Cash Amount",
  TOTAL_BARTER: "Total Barter",
  TOTAL_BARTER_AMOUNT: "Total Barter Amount",
  TOTAL_CUSTOMER_ACCOUNT: "Total Customer Account",
  TOTAL_INVOICES: "Total Invoices",
  RECORDS: "Record(s)",
  TRANSACTION_REPORT: "Transaction Report",
  DISPLAY_SORT_OPTIONS: "Sort Options",
  DEFAULT_COMPANY_DATE: "Default (Company, Date)",
  DATE_COMPANYNAME: "Date, Company Name",
  SHOW_PRODUCT_TRANSFER: "Show Product Transfers",
  HIDE_PRODUCT_TRANSFER: "Hide Product Transfers",
  SHOW_PAYMENT_TRANSFER: "Show Payment Credit/Debit",
  HIDE_PAYMENT_TRANSFER: "Hide Payment Credit/Debit",
  SUMMARY: "Summary",
  SUMMARY_ONLY: "Summary Only",
  TOTAL_AMOUNT: "Total Amount",
  PREPAY_CREDIT: "Prepay/Credit",
  CURRENT: "Current",
  ONE_TO_THIRTY: "1-30",
  THIRTY1_TO_SIXTY: "31-60",
  SIXTY1_TO_NINGHTY: "61-90",
  NIGHNTY1_TO_ONETWENTY: "91-120",
  ONETWENTY: "120+",
  SUB_TOTAL: "Sub Total",
  CUSTOMER: "Customer",
  CI: "CI #",

  DAYS_PAST_DUE_DATE: "Days Past Due Date",
  ORDER_ID: "Order ID",
  BILLING_CONTACT: "Billing Contact",
  BILLING_DATE: "Billing Date",
  GROSS: "Gross",
  NET: "Net",
  AMOUNT: "Amount",
  DAYS_PAST_DUE_DATE_TEXT: "Days Past Due Date Text",
  AGING_SUMMARY: "Aging Summary",
  DAYS_OVER_DUE: "Days Over Due",
  TOTAL_CALLS: "Total Calls",
  TOTAL_NOTES: "Total Notes",
  TASK_LIST: "Task List",
  TOTAL_MEETINGS: "Total Meetings",
  TOTAL_EMAILS: "Total Emails",
  CALLS_EMAILS_LAST_TWELVE_MONTHS: "Calls and Meetings last 12 months",
  TYPE: "Type",
  NOTES: "SpecialNotes",
  FULLNOTES: "FullNotes",
  DATE_SCHEDULED: "DateScheduled",
  DATE_COMPLETED: "DateCompleted",
  DATE_CREATED: "DateCreated",
  NAME: "Name",
  COMPANYNAME: "CompanyName",
  CREATED_BY: "CreatedBy",
  MEETING_NAME: "MeetingName",
  ASSIGNED_TO: "AssignedTo",
  ASSIGNED_BY: "AssignedBy",
  ACTIVITY_TYPE: "Activity Type",
  MARK_COMPLETE: "Complete/Uncomplete",
  CONTACT_STATUS: "PastOrFuture",
  //Line item Theme columns
  THEME_TYPE: "Theme Type",

  //PrepayApply
  CUSTOMER_INVOICE: "Customer/Invoice",
  CURRENT_BAL: "Current",
  BAL_1_30: "1-30",
  BAL_31_60: "31-60",
  BAL_61_90: "61-90",
  BAL_91_120: "91-120",
  BAL_120_PLUS: "120+",
  SUB_TOTAL_BAL: "Sub Total",
  PREPAY_CREDIT_BAL: "Prepay/Credit",
  TOTAL_BAL: "Total",
  //BatchBilling
  MC_SUBTOTAL: "MC Subtotal",
  MERCHANT_INFO: "Merchant Info",
  CC_INFO: "CC Info",
  CARD_EXP: "Exp Date",
  CARD_NUMBER: "Card #",
  CARD_TYPE: "Card Type",
  //JobSearch
  JOB: "Name",
  JOB_CREATED_DATE: "Created",
  JOB_TYPE: "Type",
  JOB_CREATED_BY: "Created By",
  JOB_QUEUED: "Queued",
  JOB_SENT: "Sent",
  JOB_EMAIL_REPORT: "Email Report",
  JOB_VIEW_NAME: "Name",
  JOB_VIEW_EMAIL: "Email",
  JOB_VIEW_SENT: "Sent",
  JOB_VIEW_TEXT: "Text",
  JOB_VIEW_QUEUED: "Queued",
  JOB_VIEW_EXCEPTION: "Exception",
  JOB_TOTAL: "Total",

  //Payment Transfers Credit/Debit
  PAYMENTS_TRANSFER_CREDIT_DEBIT: "Payments Credit/Debit",

  //AR GL Summary Report
  REVENUE: "Revenue by Charge Type",
  RECEIVABLE: "Receivables by Charge Type",
  REVENUESUMMARY:"Revenue Account Summary",
  RECEIVABLESUMMARY:"Receivables Account Summary"
});

export const invoiceSaveAlert = (value, type) => {
  switch (type) {
    case "invoiceCreate":
      return `Your invoices are being created as a background process. Once completed, you will receive a notification in your Inbox with a link to these invoices using Reference Id: ${value}. Feel free to use the system normally until you are notified.`;
  }
};
export const PLAN_FEATURES_OPTIONS = {
  Blank: "Blank",
  Available: "Available",
  UnAvailble: "Unavailable",
};

export const ALIGNMENT_HELP_TEXT = {
  HelpNote:
    "The alignment charge setting defines how you will charge for subscriptions that start before the next billing cycle.",
  None: "Do not charge any amount for the remaining days of the billing cycle. The first full billing cycle will be charged at the next start date but the subscription will be active immediately",
  FullAmount:
    "A new subscription starting before the next billing cycle start date will be charged the full amount for the current billing cycle. They will not get a prorated charge or discount for partial billing cycles.",
  Prorated:
    "Calculate the number of days remaining in the billing cycle and charge new subscriptions for that percentage of the full amount. They only have to pay for the days in the current billing cycle the subscription is active.",
};

export const BILLING_HELP_TEXT = {
  HelpNote:
    "The alignment charge setting defines how you will charge for subscriptions that start before the next billing cycle.",
  None: "Do not charge any amount for the remaining days of the billing cycle. This would be used if you did not provide a refund on pause to keep from overcharging the subscriber.",
  FullAmount:
    "Charge the subscriber for the full amount of the billing cycle. This could be used if you provided a full refund on pause to keep from not charging subscribers for their active subscription.",
  Prorated:
    "Calculate the number of days remaining in the current billing cycle and charge the resumed subscriptions for that percentage of the full amount. They only have to pay for the days in the current billing cycle the subscription is active.",
};

export const BILLING_STATEMENT_HELP_TEXT = {
  ToggleLabel:
    "Create statements per billing contact. If selected, pre-payments will NOT show on statements since these cannot be posted per billing contact (only per customer); once prepayments are applied to an invoice, they will show on the statements. If not selected, the statements will be created per customer.",
  Note: "*Company Name, First Name and Last Name will search both Customer and Bill To name.",
  AgencyToggleLabel:
    'Search for ad agencies and create statements that include billing & payment information for all of the customers linked to those agencies. These statements can contain many companies along with a lot of data depending on the search criteria used. If the "statements per billing contact" is selected above, multiple agency statements could be created for their billing contacts.',
};

export const CP_Subscription_Headers = [
  "Subscription Number",
  "Status",
  " Plan Name",
  "Price/Billing Cyle",
  "End Action",
  "End Date",
];

export const DaysOverDueColorCodes = {
  0: "#434343",
  "1-30": "#4a88db",
  "31-60": "#6651d8",
  "61-90": "#f7941f",
  "91-120": "#d52f92",
  "120+": "#f70000",
};

export const NOTE_TYPE = {
  Call: "call",
  Meeting: "meeting",
  Note: "note",
  Letter: "letter",
  MassEmail: "mass email",
  Email: "email",
  Task: "task",
  UserLogin: "user login"
};
export const productHelpText = {
  PRODUCT_FEATURES_HELP_TEXT:
    "Product features show up on the checkout pages when purchasing this product. They can help customers decide between products or be used to showcase parts of this product. You can enter any number of features and show an available, unavailable, or no icon next to the feature.",
};
