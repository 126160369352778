import React from "react";
import { createRoot } from "react-dom/client";
import { getSessionValue, getGlobalMessage } from "./commonHelpers";
import {
  cpSession,
  cpSessionKeys,
} from "../components/CustomerPortal/helpers/cpSession";
import {
  setSessionValue,
  resetSession,
  getSessionStorageValue,
} from "../utilities/sessionHelpers";
import { decrypt, authEncryptDecryptKey } from "../utilities/commonHelpers";
import { showError } from "../shared/ConfirmPopup";

/* const baseURL = "";
const domain = "mirabeldev";
const token = "MTcg+KD38qgfPwO0/iLRBn0oJOkUcOv7mR76MrWw6jmuLlDW2reUeA=="; */
/* const baseURL = "https://tsqb.magazinemanager.biz";
const domain = "tsqb";
const token = "L2OKSAd0A9J90jA6m75aRGZSd3RkV2O9tV6nxcz6U33dlqSAHWQmStYPviAmp3Y1"; */

export const apiCall = (
  endpoint,
  method = "GET",
  data = {},
  headers = {},
  cache = "no-cache",
  mode = "cors",
  options = {},
  apiOptions = false,
  dynamicBaseURL
) => {
  let baseURL, domain, token;
  
  if (process.env.NODE_ENV === "development") {
    //Below needs to be for development
    // baseURL = process.env.REACT_APP_PRODUCT === "CP" ? process.env.REACT_APP_API_BASE_URL : "https://mirabeldev.magazinemanager.com/";
    // domain = "localhost";
    // baseURL = "http://localhost";
    let TokenDetails = localStorage.getItem("TokenData");
    if(TokenDetails){
      TokenDetails = JSON.parse(TokenDetails)
      baseURL = `https://${TokenDetails?.mainurl || "mirabeldev.magazinemanager.com"}`;
      domain = `${ TokenDetails?.subdomain || "mirabeldev"}`;
    }
    else{
    baseURL = "https://mirabeldev.magazinemanager.com";
    domain = "mirabeldev";
    }
    if (window.location.pathname.startsWith(`/ui/ar/paywithsavedcards`)) {
      let _domain = window.location.hostname
        .split(".")[0]
        .replace("-", "")
        .replace("_", "");
      token = getSessionValue("PGToken_" + _domain);
    } else {
      token =
        process.env.REACT_APP_PRODUCT === "CP"
          ? cpSession().AccessToken()
          : TokenDetails?.Token || ""; // If TokenDetails.Token isn't working, paste your token in double quotes.
    }
  } else if (process.env.NODE_ENV === "test") {
    baseURL = "";
    module.exports = require("../mockUtilities/server");
  } else {
    //Below is for build
    baseURL =
      process.env.REACT_APP_PRODUCT === "CP"
        ? process.env.REACT_APP_API_BASE_URL
        : "";
    domain = getGlobalMessage(`Domain`);
    if (window.location.pathname.startsWith(`/ui/customer/proposals/view`)){
      if(window.location.search.toLowerCase().includes("approvalstage")){
        token = getSessionStorageValue("PAStagesToken");
      } else {
          token = getSessionValue("PASToken");
      }
    } else if (window.location.pathname.toLowerCase().startsWith(`/ui/reports/sharereport`)) {
      token = getSessionValue("ShareReportToken");
    } else if (window.location.pathname.startsWith(`/ui/ce/`)) {
      token = getSessionStorageValue("CEToken");
    } else {
      token =
        process.env.REACT_APP_PRODUCT === "CP"
          ? cpSession().AccessToken()
          : getSessionValue("Token");
    }
  }

  const fullUrl = (dynamicBaseURL ? dynamicBaseURL : baseURL) + endpoint;
  let _headers = new Headers({
    Accept: "application/json",
    "Content-Type": "application/json",
    domain,
    Authorization: `Bearer ${token}`,
  });
  Object.keys(headers).forEach((key) => {
    _headers.append(key, headers[key]);
  });
  let request = {};

  if (method !== "GET") {
    request = new Request(fullUrl, {
      method: method,
      mode: mode,
      cache: cache,
      headers: _headers,
      // credentials: "include",
      body: JSON.stringify(data),
    });
  } else {
    request = new Request(fullUrl, {
      method: method,
      mode: mode,
      cache: cache,
      headers: _headers,
      // credentials: "include"
    });
  }
  return fetch(request, options)
    .then((res) => {
      if (!res.ok) {
        console.log("Response is not  OK", res);
        let ErrorID = "";
        res
          .clone()
          .json()
          .then((response) => {
            ErrorID = response.ErrorID;
            if (ErrorID) {
              showError({ ErrorID: ErrorID });
              return;
            }
          });
        if (res.status === 301) {
          window.location.href = "/sessionout";
          return Promise.reject(res);
        } else if (res.status === 401) {
          var promise = new Promise(function (resolve, reject) {
            //generating access token using refresh token when refresh token expiry or invalid
            if (process.env.REACT_APP_PRODUCT === "CP") {
              //API Method
              generateTokenByPassingRefreshToken(
                {
                  endpoint,
                  method,
                  data,
                  headers,
                  cache,
                  mode,
                  options,
                  apiOptions,
                },
                resolve,
                reject
              );
            } else {
              //Web Method
              generateTokenWithOutPassingRefreshToken(
                {
                  endpoint,
                  method,
                  data,
                  headers,
                  cache,
                  mode,
                  options,
                  apiOptions,
                },
                resolve,
                reject
              );
            }
          });
          return promise;
        } else if (res.status === 403) {
          return Promise.reject(res);
        }
      }
      return res.json();
    })
    .catch((error) => {
      if (error.name === "Abort Error" || error.name === "AbortError") {
        return;
      }

      console.log("API call Error : ", error);
      if (apiOptions) {
        if (error.status === 403) {
          const root = createRoot(document.querySelector(apiOptions));
          root.render(<div>You don't have access to this contact!!!</div>);
        }
      }
      throw new Error(error.message || " Network request failed");
    });
};

export const webMethodCall = (
  endpoint,
  method = "GET",
  data = {},
  headers = {},
  cache = "no-cache",
  mode = "cors",
  options = {}
) => {
  if (process.env.NODE_ENV === "development") {
    const promise = new Promise(async (resolve) => {
      const { promises, getValue, devURL } = await import(
        "../developmentHelper"
      );
      const dataToPost = {
        isReactCall: true,
        datajson: data,
        requestId: getValue(),
        //url: devURL + endpoint,
        url: endpoint.startsWith("http") ? endpoint : devURL + endpoint,
        methodType: method,
      };
      const mmdeviframe = document.querySelector("#mmdeviframe");
      mmdeviframe.contentWindow.postMessage(dataToPost, "*");
      promises[dataToPost.requestId] = resolve;
    });
    return promise;
  } else {
    const fullUrl = endpoint;
    let _headers = new Headers({
      Accept: "application/json",
      "Content-Type": "application/json",
    });
    Object.keys(headers).forEach((key) => {
      _headers.append(key, headers[key]);
    });
    let request = {};

    if (method !== "GET") {
      request = new Request(fullUrl, {
        method: method,
        mode: mode,
        cache: cache,
        headers: _headers,
        body: JSON.stringify(data),
      });
    } else {
      request = new Request(fullUrl, {
        method: method,
        mode: mode,
        cache: cache,
        headers: _headers,
      });
    }
    return fetch(request, options)
      .then((res) => {
        if (!res.ok) {
          console.log("Response is not  OK");
          if (res.status === 301) {
            window.location.href = "/sessionout";
          }
          return Promise.reject(res);
        }
        return res.json();
      })
      .catch((error) => {
        if (error.name === "Abort Error") {
          return;
        }
        console.log("API call Error : ", error);
        throw new Error(error.message || " Network request failed");
      });
  }
};

const generateTokenByPassingRefreshToken = (params, resolve, reject) => {
  const UserLoginDTO = {
    GrantType: 1,
    RefreshToken: decrypt(cpSession().RefreshToken(), authEncryptDecryptKey),
  };
  apiCall("/services/Authentication/Customer/Data", "POST", UserLoginDTO)
    .then((resp) => {
      if (resp.content.Status === "Success") {
        resp.content.Data &&
          resp.content.Data.Token &&
          setSessionValue(
            cpSessionKeys.ACCESS_TOKEN,
            resp.content.Data.Token.AccessToken
          );
        resolve(
          apiCall(
            params.endpoint,
            params.method,
            params.data,
            params.headers,
            params.cache,
            params.mode,
            params.options,
            params.apiOptions
          )
        );
      } else {
        resetSession();
        window.location.reload();
      }
    })
    .catch((error) => {
      console.log("error while adding call:" + error);
    });
};
const generateTokenWithOutPassingRefreshToken = (params, resolve, reject) => {
  webMethodCall(
    "/intranet/Members/Home/Home.aspx/GenerateTokenByRefreshToken",
    "POST"
  )
    .then((resp) => {
      if (resp.d.Status === 200) {
        var MMClientVars = JSON.parse(
          window.localStorage.getItem("MMClientVars")
        );
        MMClientVars.Token = resp.d.Data.AccessToken;
        MMClientVars.UserId = 23;
        window.localStorage.setItem(
          "MMClientVars",
          JSON.stringify(MMClientVars)
        );
        resolve(
          apiCall(
            params.endpoint,
            params.method,
            params.data,
            params.headers,
            params.cache,
            params.mode,
            params.options,
            params.apiOptions
          )
        );
      }
    })
    .catch((error) => {
      console.log("error while adding call:" + error);
      if (window.location.pathname.startsWith(`/ui/ce/`)) {
        reject(error);
      }
    });
};
