export const ProductionModuleSummaryFields = [
    {
      SummaryFieldID: 1,
      ColorCode: "#4b89dc",
      DisplayValue: "123",
      Title: "Total",
      IsSelected: true,
      DataIndex: "Total",
      type: 1,
    },
    {
      SummaryFieldID: 2,
      ColorCode: "",
      DisplayValue: "456789",
      Title: "Total Ad Arrived",
      IsSelected: true,
      DataIndex: "TotalAdArrived",
      type: 6,
    },
    {
      SummaryFieldID: 3,
      ColorCode: "",
      DisplayValue: "12345",
      Title: "Total Ad Done",
      IsSelected: true,
      DataIndex: "TotalAdDone",
      type: 6,
    },
    {
      SummaryFieldID: 4,
      ColorCode: "#4b89dc",
      DisplayValue: "1234567",
      Title: "Production Charges",
      IsSelected: true,
      DataIndex: "ProdCharges",
      type: 3,
    },{
      SummaryFieldID: 5,
      ColorCode: "#4b89dc",
      DisplayValue: "345",
      Title: "Total Pages",
      IsSelected: true,
      DataIndex: "TotalPages",
      type: 2,
    }
  ];
