import { apiCall } from "../../../utilities/apiCall";
import {API_MM_NOTIFICATION_ACTIVE_GET_BY_PAGE,
    API_MM_NOTIFICATION_DONOTSHOW_POST,
    API_MM_NOTIFICATION_SAVE,
    API_DROPDOWN_VALUES,
    API_MM_DELETE,
    API_MM_NOTIFICATIONS_COPY
} from  "../../../utilities/apiUrls";

export const notificaitonsGetAll=(page,limit)=>{
    return apiCall (`/services/mm/notifications/all?page=${page}&limit=${limit}`);
}

export const notificationsDelete=(notificationID)=>{
    return apiCall (`${API_MM_DELETE}/${notificationID}/false`,"DELETE");
}

export const notificationDropdownData=()=>{
    return apiCall (`${API_DROPDOWN_VALUES}`)
}

export const notificatioSave=(data)=>{
    return apiCall (`${API_MM_NOTIFICATION_SAVE}`, "POST", data)
}
export const notificationDetails=(page,isAdmin,IsSA)=>{
    return apiCall (`${API_MM_NOTIFICATION_ACTIVE_GET_BY_PAGE}${page}/${isAdmin}/${IsSA}`)
}

export const dontShowAgainNotification=(id)=>{
    return apiCall (`${API_MM_NOTIFICATION_DONOTSHOW_POST}${id}`,"POST",null)
}

export const notificationCopy=(data)=>{
    return apiCall (`${API_MM_NOTIFICATIONS_COPY}`,"POST",data)
}


const notificationData1111 = [
    {
        id: 1,
        textHeader: "Another New Feature!",
        textConent: "Today we released an  update to the Sales Dashboard.If you are experiencing a white screen upon logging in please try refreshing your browser window or logging out and back into the site.if niether of those options help,please clear your browser cache"
    },
    {
        id: 2,
        textHeader: "Another New Feature!11111111111",
        textConent: "Today we released an  update to the Sales Dashboard.If you are experiencing a white screen upon logging in please try refreshing your browser window or logging out and back into the site.if niether of those options help,please clear your browser cache Today we released an  update to the Sales Dashboard.If you are experiencing a white screen upon logging in please try refreshing your browser window or logging out and back into the site.if niether of those options help,please clear your browser cache"
    }
];
export const notificationsGetByHeight=()=>{
    return notificationData1111;
}