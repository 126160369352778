import React, { useMemo } from "react";
import { createPortal } from "react-dom";
import PropTypes from "prop-types";

import "./Modal.scss";

Modal.propTypes = {
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    onClose: PropTypes.func,
    onSave: PropTypes.func,
};

function Modal(props) {
    const { onClose, title, children, style, showTransparent=false,modalClassName="",showClose=true, modalHeaderStyles={}, modalBodyStyles={} ,showgotoicon,openChangeOrderPage} = props;
    const bodyElement = useMemo(() => document.querySelector("body"), []);
    return createPortal(
        <div className={`${showTransparent ? "overlay-transp" : "overlay"}`} data-testid="modal">
            <div className={"modal "+modalClassName} style={style}>
                <header className="modal-header" style={modalHeaderStyles}>
                    <div>
                        <span className="modal-title">{title}</span>
                        {showgotoicon && <span title="Go to Change Order" className="col-gotoicon Modalgoto-icon" onClick={openChangeOrderPage}></span>}
                    </div>
                    {showClose && <span className="modal-close" onClick={onClose} />}
                </header>
                <section className="modal-body" style={modalBodyStyles}>{children}</section>
            </div>
        </div>,
        bodyElement
    );
}

export default Modal;
