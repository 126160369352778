import React from "react";
import PropTypes from "prop-types";
import loadingIcon from "../images/loading-icon.gif";
import "./Loading.scss";

Loading.propTypes = {
  loadingText: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  displayLoadingText: PropTypes.bool
};

function Loading(props) {
    const { displayLoadingText = true } = props;
  return (
    <div className="overlay" data-testid="loader">
      {displayLoadingText && (
        <div className="container-center loading">
          <img src={loadingIcon} alt="loading" />
          <div>{props.loadingText ? props.loadingText : "Loading"}...</div>
        </div>
      )}
    </div>
  );
}

export default Loading;
