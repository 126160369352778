import React, { useState, useEffect, useRef, useMemo, useLayoutEffect } from "react";
import "./actions/mmnotifications.scss";
import { RiArrowRightSLine } from "react-icons/ri";
import { RiArrowLeftSLine } from "react-icons/ri";
import classNames from "classnames";
import { Rnd } from "react-rnd";
import { formatDate, getTextFromHTML, getSessionValue, showAlert } from "../../utilities/commonHelpers";
import { notificationDetails, dontShowAgainNotification } from "./Services/NotificationService";


const NotificationPopUp = (props) => {
    const { popupNotifications, notificationsPage } = props;
    const [popUpNotificationsData, setPopUpNotificationsData] = useState(popupNotifications)
    const [initialIndex, setInitialIndex] = useState(0)
    const [selectedPopUp, setSelectedPopUP] = useState(popupNotifications[initialIndex]);
    const [popUPSizerData, setPopUpSizerDataW] = useState({
        width: selectedPopUp.popupProperties.popUpWidth,
        height: selectedPopUp.popupProperties.popUpHeigth,
    })
    const [popupAxisDirection, setPopUpAxisDirection] = useState({
        x: selectedPopUp.popupProperties.x,
        y: selectedPopUp.popupProperties.y,
    });
    const isSaUser = getSessionValue("IsSA");
    const isAdmin = getSessionValue("IsAdmin");


    const style = {
        "background-color": "#FFFF",
        "border-radius": " 4px",
        "border": "1px solid #d8d8d8",
    };

    const [width, setWidth] = React.useState(window.innerWidth);
    const [height, setHeight] = React.useState(window.innerHeight);



    const handlePreviousClick = () => {
        const selectedIndex = initialIndex - 1;
        let selectedProperties = popUpNotificationsData[selectedIndex];
        setInitialIndex(initialIndex - 1);
        setSelectedPopUP(selectedProperties);
        setPopUpSizerDataW({
            width: selectedProperties.popupProperties.popUpWidth,
            height: selectedProperties.popupProperties.popUpHeigth,
        });
        setPopUpAxisDirection({
            x: selectedProperties.popupProperties.x,
            y: selectedProperties.popupProperties.y,
        })

    }

    const handleNextClick = () => {
        const selectedIndex = initialIndex + 1;
        let selectedProperties = popUpNotificationsData[selectedIndex];
        setInitialIndex(initialIndex + 1);
        setSelectedPopUP(selectedProperties);
        setPopUpSizerDataW({
            width: selectedProperties.popupProperties.popUpWidth,
            height: selectedProperties.popupProperties.popUpHeigth,
        });
        setPopUpAxisDirection({
            x: selectedProperties.popupProperties.x,
            y: selectedProperties.popupProperties.y,
        })

    }

    const handleModelWindowClose = () => {
        setPopUpNotificationsData([]);
        setPopUpSizerDataW({
            width: "",
            height: "",
        });
        setPopUpAxisDirection({
            x: "",
            y: "",
        })
    }

    const hadleOkClick = (popupLength, currentIndex) => {
        if (popupLength - 1 == currentIndex) {
            setPopUpNotificationsData([]);
            setPopUpSizerDataW({
                width: "",
                height: "",
            });
            setPopUpAxisDirection({
                x: "",
                y: "",
            })
        }
        else {
            handleNextClick();
        }
    }

    const getHeigthandWidthPopup = (widthProperty) => {
        if (widthProperty === 60) {
            return { x: width * 0.3, y: height * 0.25, popUpWidth: "40%", popUpHeigth: "50%" }
        }
        else if (widthProperty === 75) {
            return { x: (width * 0.175), y: height * 0.15, popUpWidth: "65%", popUpHeigth: "70%" }
        }
        else {
            return { x: (width * 0.1), y: height * 0.1, popUpWidth: "80%", popUpHeigth: "80%" }
        }
    }
    
    const handleDontShowAgain = async (data, popupLength, currentIndex) => {
        try{
            const dontShowAgainNotificationData = await dontShowAgainNotification(data.ID);
            if(dontShowAgainNotificationData.content.Status !== "Success") {
                setPopUpNotificationsData([]);
                return
            }
            const notificationDetailsData = await notificationDetails(notificationsPage, isAdmin, isSaUser);
            const { Status, List } = notificationDetailsData.content
            if (Status === "Success" && List) {
                const notificationsData = List.map((data) => {
                    return {
                        ...data,
                        popupProperties: getHeigthandWidthPopup(data.Width),
                    }
                })

                if(popupLength === currentIndex+1){
                    setPopUpNotificationsData([]);
                    setPopUpSizerDataW({
                        width: "",
                        height: "",
                    });
                    setPopUpAxisDirection({
                        x: "",
                        y: "",
                    })
                    return;
                }
                setPopUpNotificationsData(notificationsData);
                setInitialIndex(currentIndex);
                let selectedProperties = notificationsData[currentIndex];
                setSelectedPopUP(selectedProperties);
                setPopUpSizerDataW({
                    width: selectedProperties.popupProperties.popUpWidth,
                    height: selectedProperties.popupProperties.popUpHeigth,
                });
                setPopUpAxisDirection({
                    x: selectedProperties.popupProperties.x,
                    y: selectedProperties.popupProperties.y,
                })
            }
            else
            {
                setPopUpNotificationsData([]);
                    setPopUpSizerDataW({
                        width: "",
                        height: "",
                    });
                    setPopUpAxisDirection({
                        x: "",
                        y: "",
                    })
                    return;
            }
        }catch(err) {
            setPopUpNotificationsData([]);
        }
    }


    return (
        <>
            {popUpNotificationsData.length > 0 && <div className="overlay-notifications">
                <Rnd
                    style={style}
                    className={`notifications${selectedPopUp.Width}-maincontent`}
                    size={{ width: popUPSizerData.width, height: popUPSizerData.height }}
                    position={{ x: popupAxisDirection.x, y: popupAxisDirection.y }}
                    onDragStop={(e, d) => {
                        setPopUpAxisDirection({ x: popUPSizerData.x, y: popUPSizerData.y });
                    }}
                    onResizeStop={(e, direction, ref, delta, position) => {
                        setPopUpSizerDataW({
                            width: ref.style.width,
                            height: ref.style.height,
                            ...position
                        });
                    }}
                    cancel=".cancel"
                >
                    <header className="header-content">
                        <span>{selectedPopUp.Title}</span>
                        <span className="modal-close cancel" onClick={handleModelWindowClose} />
                    </header>
                    <div
                        dangerouslySetInnerHTML={{ __html: selectedPopUp.Message }}
                        className="html-text cancel"
                        style={{
                            padding: '1rem',
                            height: "calc(100% - 16px - 50px)",
                            overflow: "scroll",
                            lineHeight: "38px"
                        }}>
                    </div>
                    <footer className="container-footer-notification p-2">
                        <div className="box-notification">
                            <span
                                style={{ backgroundColor: "#4b89dc" }}
                               className={classNames("arrow-iconborder pointer cancel", initialIndex == 0 && "masked")}
                                onClick={handlePreviousClick}>
                                <RiArrowLeftSLine style={{ width: "27px", height: "30px", color: "white" }} />
                            </span>
                        </div>
                        <div className="bobox-notificationx"> <span className="buttonItems">
                            <button className={classNames("button button btn-blue cancel")}
                                onClick={() => hadleOkClick(popUpNotificationsData.length, initialIndex)}
                            >
                                OK
                            </button>
                            {selectedPopUp.DoNotShow && <button className="button btn-blue-border ml-4 cancel"
                                onClick={() => handleDontShowAgain(selectedPopUp, popUpNotificationsData.length, initialIndex)}
                            >
                                Got it, don't show again
                            </button>}
                        </span></div>
                        <div className="box-notification cancel"><span
                            style={{ backgroundColor: "#4b89dc" }}
                           className={classNames("arrow-iconborder ml-auto pointer", (popUpNotificationsData.length - 1) == initialIndex && "masked")}
                            onClick={handleNextClick}>
                            <RiArrowRightSLine style={{ width: "31px", height: "28px", color: "white" }} />
                        </span></div>
                    </footer>
                </Rnd>
            </div>}
        </>
    )
};

export default NotificationPopUp;




{/* {showNotificationWindow && <div className="overlay-notifications" >
                {resizeData.showPopup && <Rnd
                    style={style}
                    size={{ width: resizeData.width, height: resizeData.height }}
                    position={{ x: resizeData.x, y: resizeData.y }}
                    onDragStop={(e, d) => {
                        setReSizeData({ ...resizeData, x: d.x, y: d.y, showPopup: true });
                    }}
                    onResizeStop={(e, direction, ref, delta, position) => {
                        setReSizeData({
                            showPopup: true,
                            width: ref.style.width,
                            height: ref.style.height,
                            ...position
                        });
                    }}
                    bounds="window"
                    minWidth={500}
                    minHeight={190}

                ><header className="header-content">
                        <span>{notificationDataIndex.Title}</span>
                        <span className="modal-close" onClick={handlePopUpclose} />
                    </header>
                    <div
                        dangerouslySetInnerHTML={{ __html: notificationDataIndex.Message }}
                        className="handle"
                        style={{
                            padding: '1rem',
                            height: "84%",
                            overflow: "scroll",
                            lineHeight: "38px"
                        }}>
                    </div>
                    <footer className="container-footer-notification p-2">
                        <div className="box-notification">
                            <span
                                style={{ backgroundColor: "#4b89dc" }}
                               className={classNames("arrow-iconborder pointer", selectedindex == 0 && "masked")}
                                onClick={hadlePreviousClick}>
                                <RiArrowLeftSLine style={{ width: "27px", height: "30px", color: "white" }} />
                            </span>
                        </div>
                        <div className="bobox-notificationx"> <span className="buttonItems">
                            <button className={classNames("button button btn-blue")}
                                onClick={() => hadleOkClick(notificationData.length, selectedindex)}>
                                OK
                            </button>
                            {notificationDataIndex.DoNotShow && <button className="button btn-blue-border ml-4" onClick={() => handleDontShowAgain(notificationDataIndex, notificationData.length, selectedindex)}>
                                Got it,don't show again
                            </button>}
                        </span></div>
                        <div className="box-notification"><span
                            style={{ backgroundColor: "#4b89dc" }}
                           className={classNames("arrow-iconborder ml-auto pointer", (notificationData.length - 1) == selectedindex && "masked")}
                            onClick={hadleNextClick}>
                            <RiArrowRightSLine style={{ width: "31px", height: "28px", color: "white" }} />
                        </span></div>
                    </footer>
                </Rnd>}
            </div>
            } */}




