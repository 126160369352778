const activityTypes = Object.freeze({
    All: 4,
    Notes: 5,
    Calls: 2,
    Meetings: 3,
    Emails: 7,
    Tasks: 8,
});
const systemActivityTypes = Object.freeze({
    ToDaysCallBacks: 0,
    ThisWeekCallBacks: 1,
    AllCallbacks: 2,
    Meetings: 3,
    AllActivities: 4,
    None: 5,
    isCall: 6,
    isEmail: 7,
    isMassEmail: 8,
});
const accessLevels = Object.freeze({
    NoAccess: 0,
    ReadAccess: 1,
    EditAccess: 2,
});
const fileUploadSources = Object.freeze({
    ClassifiedFile: 1,
    ClientFile: 2,
    ContactImage: 3,
    DocumentManager: 4,
    EmailAttachment: 5,
    FileExplorerPublic: 6,
    ImportTool: 7,
    ImportToolZip: 8,
    ProductionNote: 9,
    PublisherLogo: 10,
    UserImage: 11,
    Export: 12,
    FileExplorerPrivate: 13,
    Template: 14,
    Editorial: 17,
    SubscriptonPlan: 19,
    SubscriptionAddon :20,
    ProductImage:25
});
const phoneTypes = Object.freeze({
    1: "tel",
    2: "callto",
    3: "tksuite",
});
const recordType = Object.freeze({
    Company: 1,
    SubContact: 2,
    AdAgency: 3,
    AdAgencySubContact: 4,
    Parent:5
});
const actionsPanelPageName = Object.freeze({
    ContactEdit: "ContactEdit",
    ContactSearch: "ContactSearch",
    BatchOrderUpdate: 3,
    ProposalList: 4,
});
const SocialProfiles = Object.freeze({
    WebsiteUrl: "URL",
    FacebookUrl: "FacebookId",
    LinkedInUrl: "LinkedIn",
    TwitterUrl: "TwitterHandle",
    GooglePlusUrl: "GooglePlus",
});
const Home = Object.freeze({
    Address1: "homestreet",
    Address2: "homeStreet2",
    City: "homeCity",
    State: "homest",
    Zipcode: "homezip",
    County: "homeCounty",
    International: "homeInternational",
});
const Common = Object.freeze({
    Address1: "street",
    Address2: "street2",
    City: "city",
    State: "st",
    Zipcode: "zip",
    County: "county",
    International: "international",
});
const enumSearchPageViews = Object.freeze({
    FullScreen: 1,
    SplitScreen: 2,
    KanBan: 3,
    CalendarScreen: 4,
    GanttView: 5,
    SearchScreen: 6
});
const enumOrderSearchPageViews = Object.freeze({
    ListView: 0,
    CalendarView: 1,
    ExportView: 2,
    GanttView: 3,
    KanbanView: 4,
});
const enumSalesSearchPageViews = Object.freeze({
    ListView: "0",
    CalendarView: "3",
    GanttView: "5",
    TotalsView:"6"
});
const enumSavedSearchPageTypes = Object.freeze({
    ContactSearch: 0,
    Opportunity: 1,
    Subscription: 2,
    InvoiceSearch: 4,
    OrderSearch: 5,
    SalesReport: 7,
    ActivitiesSearch: 6,
    InventoryReport:8,
    ProposalEdit:9,
    SalesByModule:10,
    JobSearch:11,
    CampaignSearch:12,
    ActivityModule:13,
    Proposal:14,
    ChangeOrderSearch:15,
    CreateInvoice:16,
    BatchBilling: 17,
    PrepayApply: 18,
    TaskList: 19
});
const enumOpportunityPageProductType = Object.freeze({
    Default: 0,
    Opportunity: 1,
    Proposal: 2
});
const enumFileUploadSource = Object.freeze({
    ProductionNote: 1,
});
const enumEmailSenderType = Object.freeze({
    Contactology: 1,
    Campaigner: 2,
    MailChimp: 3,
    Smtp: 4,
    MirabelEmail: 5,
    MailChimpPlusMKM: 6,
});
const enumProposalViewStatus=Object.freeze({
    0:"",
    1:"- Active (On Portal View)",
    2:"- Approved",
});
const enumProposalStatus=Object.freeze({
    Active:0,
    ConvertedToContract:1,
    NoLineItems:2
})
const enumChangeOrderStatus=Object.freeze({
    Deleted:0,
    Pending: 1,
    Applied:2,
})
const enumMasterDataType = Object.freeze({
    EditorialTypes: 1,
    TaskListProjects: 2,
    ActivityTypes: 3,
    Categories: 4,
    OpportunityLossReasons: 5,
    OpportunityTypes: 6,
    Prefixes: 7,
    Priorities: 8,
    TaskListPriorities: 9,
    JobFunctions: 10,
    LinkTypes: 11,
    JobDescription: 12,
    States: 13,
    Countries: 14,
    ContactStates: 18,
    ContactCountries: 19,
    DisplayTypes: 34,
    ShippingTypes: 35,
    LeadTypes:36,
    ProspectingStages:37,
    LeadSources:38,
    WorkFlows:39,
    LeadQuality:40,
    LeadStatus:41
});
const enumMasterDataUsage = Object.freeze({
    Transaction: 1 /*Returns Empty option */,
    Report: 2 /*Returns "ALL" option */,
});
const enumTemplateType = Object.freeze({
    announcement: "announcement",
    Proposal: "Proposal",
    ProposalLineItem:"ProposalLineitem",
    ProposalTemplateChange:"ProposalTemplateChange",
    Invoice: "Invoice",
    Statement: "Statement",
    ClientLetter: "ClientLetter",
    ProductionLetter: "ProductionLetter",
    CirculationLetter: "CirculationLetter",
    BeeTemplates: "BeeTemplates",
    MLTemplates: "MLTemplates",
    PaymentPlan: "PaymentPlan",
    Letter: "Letter",
    Magazine:"Magazines",
    SubscriptionLetter:"subscriptionletter",
    SubscriptionInovice:"subscriptionInovice",
    ProposalApproval: "ProposalApproval",
    ChangeOrder: "ChangeOrder",
    ChangeOrderTemplateChange:"ChangeOrderTemplateChange",
});
const enumParentProductTypes = Object.freeze({
    0: "No Price",
    2: "Print",
    4: "Digital",
    5: "Event",
    6: "Subscription",
});
const sessionDetails = JSON.parse(window.localStorage.getItem("MMClientVars"));
let enumSubProductTypes = {
    1: "Print",
    3: "Digital",
    4: "Event",
    5: "Subscription",
    7: "Editorial",
    8: "Service",
    43: "Editorial",
};
if (sessionDetails && sessionDetails["PASubProductTypeId"] && sessionDetails["PASubProductTypeName"]) {
    enumSubProductTypes[sessionDetails["PASubProductTypeId"]] = sessionDetails["PASubProductTypeName"];
}
enumSubProductTypes = Object.freeze(enumSubProductTypes);
 const enumSmartSizeModeEnum =  Object.freeze({
    "UNKNOWN": 0,
    "NONE": 1,
    "SMART_BANNER": 2,
    "DYNAMIC_SIZE": 3
   });
const enumSubProductTypeCodes = Object.freeze({
    Print: "Print",
    Digital: "Digital",
    Event: "Event",
    Subscription: "Subscription",
    Editorial: "Editorial",
    Service: "Service",
    ProgrammaticAd: "ProgrammaticAd",
  })
const enumAdsType = Object.freeze({
    "BROWSER": 0,
    "VIDEO_PLAYER": 1
  });
const enumOpportunityStatus = Object.freeze({
    Open: "Open",
    Won: "Won",
    Lost: "Lost",
});
const enumOpportunityStages = Object.freeze({
    Closed_Won: "closed_won",
    Closed_Lost: "closed_lost",
});
const enumNotificationTypes = Object.freeze({
    0: "AdArrived",
    1: "RequiresDesign",
    2: "Ad1stProof",
    3: "Ad1stChanges",
    4: "Ad2ndProof",
    5: "Ad2ndChanges",
    6: "Ad3rdProof",
    7: "AddDone",
    8: "Designer1",
    9: "Designer2"
});
const BatchUpdateSetting = Object.freeze({
    No: 0,
    ProposalEdit: 1,
    BatchOrderUpdateReport: 2,
    Both: 3
});
const enumColumnViewTypes = {
    DEFAULT_VIEWS: 1,
    PUBLIC_VIEWS: 2,
    CUSTOM_VIEWS: 0,
};
const enumColumnViewTypeNames = {
    1: "Global Views",
    2: "Global Views",
    0: "My Views",
}
const enumCustomFieldTables = {
    project: "tblCustomFieldDataProject",
    production: "tblCustomFieldDataProduction",
}
const enumJobType = {
    PRINTSTATEMENT: "PRINTSTATEMENT",
    PDFSTATEMENT: "PDFSTATEMENT",
    PRINTINVOICE: "PRINTINVOICE",
    PDFINVOICE: "PDFINVOICE",
    PDFSUBSCRIPTION: "PDFSUBSCRIPTION",
    PRINTPROPOSAL: "PRINTPROPOSAL",
    PDFPROPOSAL: "PDFPROPOSAL",
    PRINTCHANGEORDER:"PRINTCHANGEORDER",
    PDFCHANGEORDER:"PDFCHANGEORDER"
}
const enumAddressLookupType = {
    STREET_ADDRESS: 1
}
const enumOrderTypeDropDownValues = [
    { Value: 1, Display: "All" },
    { Value: 2, Display: "w/ Orders" },
    { Value: 3, Display: "w/out Orders" }
]

const enumStatusTypeDropDownValues = [
    { Value: 1, Display: "All" },
    { Value: 2, Display: "To Do" },
    { Value: 3, Display: "Completed" }
]
const enumInventoryType = {
    AdSize: 0,
    AdPosition: 1
}
const enumTermEndAction = {
    RenewOnce: {
        ID: 1,
        Name: "Renew Once"
    },
    AutoRenew: {
        ID: 2,
        Name: "Auto Renew"
    },
    Expire: {
        ID: 3,
        Name: "Expire"
    },
    UntilCancelled: {
        ID: 4,
        Name: "Until Cancelled"
    },
}

const enumBillingPeriodUnit = {
    Day: {
        ID: 1,
        Name: "Day"
    },
    Week: {
        ID: 2,
        Name: "Week"
    },
    Month: {
        ID: 3,
        Name: "Month"
    },
    Year: {
        ID: 4,
        Name: "Year"
    },
}

const enumProposalSidePanelType = Object.freeze({
    Esign: "Esign",
    CopyProposal:'Copy Proposal',
    MoveProposal:'Move Line Items',
    InvCheck:'Inv Check',
    Print: "Print",
    Pdf: "Pdf",
    Email: "Email"
});
const enumChangeOrderSidePanelType = Object.freeze({
    Print: "Print",
    Pdf: "Pdf",
    Email: "Email"
});
const enumBatchUpdateSetting= Object.freeze({
    "No" : 0,
    "ProposalEdit" : 1,
    "BatchOrderUpdateReport" : 2,
    "Both" : 3
})
const enumResponseStatus = Object.freeze({
    Success: "Success",
    AccessDenied: "AccessDenied",
    BadRequest: "BadRequest",
    Conflict: "Conflict",
    Error: "Error",
    TokenExpired: "TokenExpired",
    InvalidToken: "InvalidToken"
});
const enumClientDevice = Object.freeze({
        Phone: 1,
        Tablet: 2,
        PC: 3
});
const enumESignStatus = Object.freeze({
    Complete: "complete",
    Pending: "pending",
    Cancelled: "cancelled",
    Expired: "expired",
    SignedByOneParty: "signed-by-one-party"
});
const enumRS = Object.freeze({
    RS_PENDING: "PENDING",
    RS_SIGNED_BY_ONE_PARTY: "SIGNED-BY-ONE-PARTY"
});
const enumPricingModelTypes = Object.freeze({
   FlatFee: "1",
   PerUnit: "2",
   Tiered: "3",
   Volume: "4",
   StairStep: "5"
});
const enumDigitalAdsType = Object.freeze({
    AdCellerant: 0,
    GoogleAd: 1,
    BroadStreet: 2,
    None: 3
 });
const enumPaymentRequestType = Object.freeze({
    PaymentAndSaveProfile: "PaymentAndSaveProfile",
    SaveProfile: "SaveProfile"
});
const enumCreativeSizeType = Object.freeze({
  PIXEL: 0,
  ASPECT_RATIO: 1,
  INTERSTITIAL: 2,
  NATIVE: 3,
  AUDIO: 4,
  IGNORED: 5,
});
const enumMasterPages = Object.freeze({
  tasklistpriority: {
    heading: "Task List Priorities",
    createLabel: "Task List Priority",
    key: "tasklistpriority",
  },
  tasklistproject: {
    heading: "Task List Categories",
    createLabel: "Task List Category",
    key: "tasklistproject",
  },
  category: {
    heading: "Categories",
    createLabel: "Category",
    key: "category",
  },
  priority: {
    heading: "Priorities",
    createLabel: "Priority",
    key: "priority",
  },
  activitytype: {
    heading: "Activity Types",
    createLabel: "Activity Type",
    key: "activitytype",
  },
  prefix: {
    heading: "Prefixes",
    createLabel: "Prefix",
    key: "prefix",
  },
  opportunitytype: {
    heading: "Opportunity Types",
    createLabel: "Opportunity Type",
    key: "opportunitytype",
  },
  opportunitylossreason: {
    heading: "Opportunity Loss Reasons",
    createLabel: "Opportunity Loss Reason",
    key: "opportunitylossreason",
  },
  editorialtype: {
    heading: "Editorial Types",
    createLabel: "Editorial Type",
    key: "editorialtype",
  },
  department: {
    heading: "Departments",
    createLabel: "Department",
    key: "department",
  },
});

const enumRequestSource = Object.freeze({
    MagazineManager: "TMM",
    CustomerPortal: "MCP",
    CheckoutPage: "MSCP",
    SubscriptionPage: "MSP"
})

const partnerRepsAction = Object.freeze({
  New: "New",
  Edit: "Update",
});
const enumLineItemTheme = Object.freeze({
    Classic : 1,
    Modern : 2,
    Calendar : 3,
});

const enumAIFeaturesList = Object.freeze({
    SalesLetter: "SalesLetter",
    SalesModule: "SalesModule",
    Activity: "Activity",
    BeeEditor: "BeeEditor",
    CompanyExecutiveSummary: "CompanyExecutiveSummary",
    MeetingScheduler: "MeetingScheduler",
    LetterWriter: "LetterWriter", //Category for BeeEditor
    TextImprover: "AITextImprover" ,
    PreflightFieldMapping:"PreflightFieldMapping",//For Data preflight
    PreflightMediaKit:"PreflightMediaKit"
});

const AIProviders = Object.freeze({
    OpenAI: "openai"
})

const multipleBatchUpdateTypes = Object.freeze({
    Categories: "Categories",
    Priority: "Priority",
    ContactGroups: "AddGroup"
});

const enumCheckoutFormTypes = Object.freeze({
    Subscription: 'Subscription',
    Order: 'Order'
})

module.exports = {
    activityTypes,
    systemActivityTypes,
    fileUploadSources,
    phoneTypes,
    recordType,
    SocialProfiles,
    actionsPanelPageName,
    accessLevels,
    Home,
    Common,
    enumMasterDataType,
    enumMasterDataUsage,
    enumTemplateType,
    enumSearchPageViews,
    enumSubProductTypes,
    enumSubProductTypeCodes,
    enumSmartSizeModeEnum,
    enumAdsType,
    enumParentProductTypes,
    enumSavedSearchPageTypes,
    enumOpportunityPageProductType,
    enumOpportunityStatus,
    enumStatusTypeDropDownValues,
    enumOpportunityStages,
    enumNotificationTypes,
    enumEmailSenderType,
    enumOrderSearchPageViews,
    enumSalesSearchPageViews,
    BatchUpdateSetting,
    enumColumnViewTypes,
    enumColumnViewTypeNames,
    enumFileUploadSource,
    enumCustomFieldTables,
    enumJobType,
    enumAddressLookupType,
    enumProposalViewStatus,
    enumProposalStatus,
    enumOrderTypeDropDownValues,
    enumInventoryType,
    enumTermEndAction,
    enumProposalSidePanelType,
    enumBatchUpdateSetting,
    enumResponseStatus,
    enumClientDevice,
    enumESignStatus,
    enumRS,
    enumPricingModelTypes,
    enumPaymentRequestType,
    enumDigitalAdsType,
    enumCreativeSizeType,
    enumMasterPages,
    enumRequestSource,
    partnerRepsAction,
    enumChangeOrderStatus,
    enumChangeOrderSidePanelType,
    enumLineItemTheme,
    enumAIFeaturesList,
    enumBillingPeriodUnit,
    AIProviders,
    multipleBatchUpdateTypes,
    enumCheckoutFormTypes
};